import * as actionlist from '../actions/timereportactions';
import moment from 'moment';
const state = {
    search: '',
    page: 0,
    startdate: moment().add('days', -7).toDate(),
    enddate: new Date(),
    usertype: null,
    max: 2,
    activityLabelId: null
};

const getters = {
    page: state => state.page,
    search: state => state.search,
    startdate: state => state.startdate,
    enddate: state => state.enddate,
    activityLabelId: state => state.activityLabelId
};

const actions = {
    [actionlist.TIMEREPORTLIST_SEARCH]: ({ commit }, search) => {
        commit(actionlist.TIMEREPORTLIST_SEARCH, search);
    },
    [actionlist.TIMEREPORTLIST_PAGE]: ({ commit }, page) => {
        commit(actionlist.TIMEREPORTLIST_PAGE, page);
    },
    [actionlist.TIMEREPORTLIST_STARTDATE]: ({ commit }, startdate) => {
        commit(actionlist.TIMEREPORTLIST_STARTDATE, startdate);
    },
    [actionlist.TIMEREPORTLIST_ENDDATE]: ({ commit }, enddate) => {
        commit(actionlist.TIMEREPORTLIST_ENDDATE, enddate);
    },
    [actionlist.TIMEREPORTLIST_ACTIVITYLABELID]: ({ commit }, activityLabelId) => {
        commit(actionlist.TIMEREPORTLIST_ACTIVITYLABELID, activityLabelId);
    },
};

const mutations = {
    [actionlist.TIMEREPORTLIST_SEARCH]: (state, search) => {
        state.page = 0;
        state.search = search;
    },
    [actionlist.TIMEREPORTLIST_PAGE]: (state, page) => {
        state.page = page;
    },
    [actionlist.TIMEREPORTLIST_STARTDATE]: (state, startdate) => {
        state.page = 0;
        state.startdate = startdate;
    },
    [actionlist.TIMEREPORTLIST_ENDDATE]: (state, enddate) => {
        state.page = 0;
        state.enddate = enddate;
    },
    [actionlist.TIMEREPORTLIST_ACTIVITYLABELID]: (state, activityLabelId) => {
        state.page = 0;
        state.activityLabelId = activityLabelId;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
