import axios from 'axios';

export default {
    name: 'SelectUser',
    props: {
        value: [Number, String],
        nametype: {
            type: String,
            default: 'fullname'
        },
        inputclass: {
            type: String,
            default: ''
        },
        emptyname: {
            type: String,
            default: null
        },
        usertype: {
            type: String,
            default: 'client'
        },
        firstaidattendant: {
            default: false,
            type: Boolean
        },
        activityId: {
            default: 0,
            type: Number
        }
    },
    data() {
        return {
            users: []
        };
    },
    computed: {
        options() {
            const vm = this;
            return this.users.map(x => {
                return { id: x.id, text: vm.getName(x) };
            });
        },
    },
    created() {
        this.fetch();
    },
    methods: {
        fetch() {
            var url = APIURL + '/applicationuser/list/' + this.usertype + '/?userids=current';
            if (this.firstaidattendant) {
                url = APIURL + '/activity/firstaidattendant/' + this.activityId;
            }
            axios.get(url)
                .then(resp => this.users = resp.data.list);
        },
        getName(user) {
            return this.$options.filters.nameLong(user);
        }
    }
};