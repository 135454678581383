import { render, staticRenderFns } from "./spinner.vue?vue&type=template&id=2fddea52&"
import script from "./spinner.js?vue&type=script&lang=js&"
export * from "./spinner.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\e-trailor\\Bont\\Frontend\\BontScheduler.Portal\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2fddea52')) {
      api.createRecord('2fddea52', component.options)
    } else {
      api.reload('2fddea52', component.options)
    }
    module.hot.accept("./spinner.vue?vue&type=template&id=2fddea52&", function () {
      api.rerender('2fddea52', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "ClientApp/components/common/spinner.vue"
export default component.exports