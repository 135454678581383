var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "d-flex ht-150 pos-relative align-items-center" },
      [
        _c("div", { staticClass: "sk-double-bounce" }, [
          _c("div", { staticClass: "sk-child sk-double-bounce1 bg-gray-800" }),
          _vm._v(" "),
          _c("div", { staticClass: "sk-child sk-double-bounce2 bg-gray-800" })
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }