import * as actionlist from '../actions/clubreportactions';
import moment from 'moment';
const state = {
    search: '',
    page: 0,
    startdate: moment().subtract(1, 'months').date(1).toDate(),
    enddate: moment().subtract(1, 'months').endOf('month').toDate(),
    clubId: null   
    
};

const getters = {
    page: state => state.page,
    search: state => state.search,
    startdate: state => state.startdate,
    enddate: state => state.enddate,
    clubId: state => state.clubId,
};

const actions = {
    [actionlist.CLUBREPORTLIST_SEARCH]: ({ commit }, search) => {
        commit(actionlist.CLUBREPORTLIST_SEARCH, search);
    },
    [actionlist.CLUBREPORTLIST_PAGE]: ({ commit }, page) => {
        commit(actionlist.CLUBREPORTLIST_PAGE, page);
    },
    [actionlist.CLUBREPORTLIST_STARTDATE]: ({ commit }, startdate) => {
        commit(actionlist.CLUBREPORTLIST_STARTDATE, startdate);
    },
    [actionlist.CLUBREPORTLIST_ENDDATE]: ({ commit }, enddate) => {
        commit(actionlist.CLUBREPORTLIST_ENDDATE, enddate);
    },
    [actionlist.CLUBREPORTLIST_CLUBID]: ({ commit }, clubId) => {
        commit(actionlist.CLUBREPORTLIST_CLUBID, clubId);
    },
};

const mutations = {
    [actionlist.CLUBREPORTLIST_SEARCH]: (state, search) => {
        state.page = 0;
        state.search = search;
    },
    [actionlist.CLUBREPORTLIST_PAGE]: (state, page) => {
        state.page = page;
    },
    [actionlist.CLUBREPORTLIST_STARTDATE]: (state, startdate) => {
        state.page = 0;
        state.startdate = startdate;
    },
    [actionlist.CLUBREPORTLIST_ENDDATE]: (state, enddate) => {
        state.page = 0;
        state.enddate = enddate;
    },
    [actionlist.CLUBREPORTLIST_CLUBID]: (state, clubId) => {
        state.page = 0;
        state.clubId = clubId;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
