export default {
    name: 'ControlPanel',
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        hasDefaultSlot () { // check if the slots are filled to show dropdown or not
            return !!this.$slots.default;
        },
    }
};