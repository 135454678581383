import { AUTH_LOGOUT } from '../../store/actions/authactions';
import { mapActions, mapGetters } from 'vuex';

export default {
    components: {
    },
    name: 'NavMenu',
    computed: {
        ...mapGetters('auth', [
            'isAdministrator',
            'isAttendant',
            'isClient',
            'isClientReadOnly',
            'isLimitedAdministrator',
            'user',
            'isCoordinatingAttendant',
            'isIndividualReportVisible'
        ]),

    },
    methods: {
        subIsActive(input) {
            const paths = Array.isArray(input) ? input : [input];
            return paths.some(path => {
                return this.$route.path.indexOf(path) === 0; // current path starts with this path string
            });
        },
        logout: function () {
            this.doLogout()
                .then(() => {
                    this.$router.push({ name: 'login' });
                });
        },
        ...mapActions('auth', {
            doLogout: AUTH_LOGOUT
        }),
        AccessClient(readonly) {
            return (this.isClient || this.isClientReadOnly) && (readonly == this.isClientReadOnly)
                || (this.user.parentid != '0' && this.isClient);
        },
        AccessAttendant() {
            return (this.isAttendant || this.isLimitedAdministrator);
        },


    }
};