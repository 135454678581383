import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
    name: 'CalendarControlMobile',
    props: {


    },
    data() {
        return {
            date: moment().toDate(),
            date1: new Date()
        };

    },
    watch: {
        //activityLabelId() {
        //    this.$emit('activitylabelchange', this.activityLabelId);
        //},
    },
    methods: {
        nextPeriod() {

            this.date = moment(this.date).add(1, 'days').toDate();

            this.$emit('datechange', this.date);
            console.log('next' + this.date);
        },
        previousPeriod() {
            this.date = moment(this.date).add(-1, 'days').toDate();
            this.$emit('datechange', this.date);
            console.log('prev' + this.date);
        },
        datechange() {           
            this.$emit('datechange', this.date);
            console.log('date' + this.date);
        },
    },
    computed: {
        ...mapGetters('auth', [
            'isAdministrator',
            'isAttendant',
            'isClient',
            'isClientReadOnly',
            'isLimitedAdministrator'
        ])
    },
};