import { LIST_FORCEDESKTOP } from '../actions/scheduleactions';

const state = {
    forcedesktop: true
};

const getters = {    
    forcedesktop: state => state.forcedesktop
};

const actions = {
    [LIST_FORCEDESKTOP]: ({ commit }, forcedesktop) => {
        commit(LIST_FORCEDESKTOP, forcedesktop);
    },
};

const mutations = {
    [LIST_FORCEDESKTOP]: (state, forcedesktop) => {
        state.forcedesktop = forcedesktop;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};