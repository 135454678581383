import Vue from 'vue';

import Calendar from '../components/common/calendar/calendar.vue';
import CalendarControl from '../components/common/calendar/calendarControl.vue';
import FormGroup from '../components/common/formgroup.vue';
import FormGroupCheck from '../components/common/formgroupcheck.vue';
import InputNumber from '../components/common/inputs/inputnumber.vue';
import InputCheck from '../components/common/inputs/inputcheck.vue';
import InputText from '../components/common/inputs/inputtext.vue';
import SelectYear from '../components/common/inputs/selectyear.vue';
import Spinner from '../components/common/spinner.vue';
import wysiwyg from 'vue-wysiwyg';
import ControlPanel from '../components/common/controlpanel.vue';
import ButtonDelete from '../components/common/buttons/buttondelete.vue';
import ButtonSave from '../components/common/buttons/buttonsave.vue';
import Search from '../components/common/inputs/search.vue';
import Paging from '../components/common/paging.vue';
//import bModal from 'bootstrap-vue/esm/components/modal/modal';
//import bModalDirective from 'bootstrap-vue/esm/directives/modal/modal';
import DatePicker from '../components/common/inputs/datepicker.vue';
import ReadonlyText from '../components/common/readonly/readonlytext.vue';
import InputTextArea from '../components/common/inputs/inputtextarea.vue';
import SelectEnumType from '../components/common/inputs/selectenumtype.vue';
import TimePicker from '../components/common/inputs/timepicker.vue';
import { default as Vuedals, Component as Vuedal, Bus as VuedalsBus } from 'vuedals';
import SelectTemplate from '../components/common/inputs/selecttemplate.vue';
import InputToggle from '../components/common/inputs/inputtoggle.vue';
import SelectUser from '../components/common/inputs/selectuser.vue';
import Quill from 'quill';
import VueQuillEditor from 'vue-quill-editor';
import ImageResize from 'quill-image-resize-module';
import QuillEdit from '../components/common/inputs/quill.vue';
import Select2 from '../components/common/inputs/Select2.vue';
import SelectOptions from '../components/common/inputs/selectoptions.vue';
import ProgressDialog from '../components/common/inputs/progressdialog.vue';
import SelectMonth from '../components/common/inputs/selectmonth.vue';
import InputFile from '../components/common/inputs/inputfile.vue';

import CalendarMobile from '../components/common/calendar/calendarMobile.vue';
import CalendarControlMobile from '../components/common/calendar/calendarControlMobile.vue';
import CalendarBirthday from '../components/common/calendar/calendarbirthday.vue';
import CalendarAttendant from '../components/common/calendar/calendarattendant.vue';
// Global components
Vue.component('paging', Paging);
Vue.component('search', Search);
Vue.component('calendar', Calendar);
Vue.component('calendar-control', CalendarControl);

Vue.component('formgroup', FormGroup);
Vue.component('formgroupcheck', FormGroupCheck);
Vue.component('selectyear', SelectYear);
Vue.component('inputnumber', InputNumber);
Vue.component('inputtext', InputText);
Vue.component('inputcheck', InputCheck);
Vue.component('spinner', Spinner);
Vue.component('controlpanel', ControlPanel);
Vue.use(wysiwyg, {forcePlainTextOnPaste: true});
Vue.component('buttondelete', ButtonDelete);
Vue.component('buttonsave', ButtonSave);
//Vue.component('b-modal', bModal);
//Vue.directive('b-modal', bModalDirective);
Vue.component('datepicker', DatePicker);
Vue.component('readonlytext', ReadonlyText);
Vue.component('inputtextarea', InputTextArea);
Vue.component('selectenumtype', SelectEnumType);
// Don't forget to call this
Vue.use(TimePicker);
Vue.component('timepicker', TimePicker);
Vue.component('inputtoggle', InputToggle);
Vue.use(Vuedals);
Vue.component('vuedal', Vuedal);
Vue.component('selecttemplate', SelectTemplate);
Vue.component('selectuser', SelectUser);
Vue.component('quill', QuillEdit);
Vue.component('select2', Select2);
Vue.component('selectoptions', SelectOptions);
Vue.use(VueQuillEditor);
Vue.component('progressdialog', ProgressDialog);
Quill.register('modules/imageResize', ImageResize);
Vue.component('selectmonth', SelectMonth);
Vue.component('inputfile', InputFile);
Vue.component('calendar-mobile', CalendarMobile);
Vue.component('calendar-control-mobile', CalendarControlMobile);
Vue.component('calendar-birthday', CalendarBirthday);
Vue.component('calendar-attendant', CalendarAttendant);
// Register a global custom directive called `v-focus`
Vue.directive('focus', {
    inserted: function (el) {
        el.focus();
    }
});


import VuejsDialog from 'vuejs-dialog';
Vue.use(VuejsDialog,{ 
    html: true,
    loader: false,
    okText: 'Ok',
    cancelText: 'Annuleren',
    animation: '',
});