
import { mapGetters } from 'vuex';
import moment from 'moment';
export default {
    components: {
      
    },
    name: 'Weekday',
    data() {
        return {            
            weekdays: [
                this.$t('periods.long.monday'),
                this.$t('periods.long.tuesday'),
                this.$t('periods.long.wednesday'),
                this.$t('periods.long.thursday'),
                this.$t('periods.long.friday'),
                this.$t('periods.long.saturday'),
                this.$t('periods.long.sunday')
            ]
        };
    },
    props: {
        day: {
            type: Object
        },
        active: {
            type: Boolean,
            default: true
        },
        showDayOfMonth: {
            type: Boolean,
            default: true
        },
        isMobile: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters('auth', [
            'isAdministrator',
        ]),
        getClass() {
            let css = '';
            if (!this.active) {
                css = 'bg-black-1';
            }
            if (this.getdate()) {
                css = css + ' khakhi';
            }
          
            return css;
        }
    },
    methods: {
        getdate() {            
            let now = moment();
            let datemoment = moment(this.day.date);
            return now.format('D') == datemoment.format('D') && now.format('M') == datemoment.format('M');
        }
    }
};