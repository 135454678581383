import * as actionlist from '../actions/medicationreportactions';
import moment from 'moment';
const state = {
    search: '',
    page: 0,
    startdate: moment().date(1).toDate(),
    enddate: moment().endOf('month').toDate(),
    administratorStatus: null,
    activityLabelId:null

};

const getters = {
    page: state => state.page,
    search: state => state.search,
    startdate: state => state.startdate,
    enddate: state => state.enddate,
    activityLabelId: state => state.activityLabelId,
    administratorStatus: state => state.administratorStatus
};

const actions = {
    [actionlist.MEDICATIONREPORTLIST_SEARCH]: ({ commit }, search) => {
        commit(actionlist.MEDICATIONREPORTLIST_SEARCH, search);
    },
    [actionlist.MEDICATIONREPORTLIST_PAGE]: ({ commit }, page) => {
        commit(actionlist.MEDICATIONREPORTLIST_PAGE, page);
    },
    [actionlist.MEDICATIONREPORTLIST_STARTDATE]: ({ commit }, startdate) => {
        commit(actionlist.MEDICATIONREPORTLIST_STARTDATE, startdate);
    },
    [actionlist.MEDICATIONREPORTLIST_ENDDATE]: ({ commit }, enddate) => {
        commit(actionlist.MEDICATIONREPORTLIST_ENDDATE, enddate);
    },
    [actionlist.MEDICATIONREPORTLIST_ACTIVITYLABELID]: ({ commit }, activityLabelId) => {
        commit(actionlist.MEDICATIONREPORTLIST_ACTIVITYLABELID, activityLabelId);
    },
    [actionlist.MEDICATIONREPORTLIST_ADMINISTRATORSTATUS]: ({ commit }, administratorStatus) => {
        commit(actionlist.MEDICATIONREPORTLIST_ADMINISTRATORSTATUS, administratorStatus);
    },
};

const mutations = {
    [actionlist.MEDICATIONREPORTLIST_SEARCH]: (state, search) => {
        state.page = 0;
        state.search = search;
    },
    [actionlist.MEDICATIONREPORTLIST_PAGE]: (state, page) => {
        state.page = page;
    },
    [actionlist.MEDICATIONREPORTLIST_STARTDATE]: (state, startdate) => {
        state.page = 0;
        state.startdate = startdate;
    },
    [actionlist.MEDICATIONREPORTLIST_ENDDATE]: (state, enddate) => {
        state.page = 0;
        state.enddate = enddate;
    },
    [actionlist.MEDICATIONREPORTLIST_ACTIVITYLABELID]: (state, activityLabelId) => {
        state.page = 0;
        state.activityLabelId = activityLabelId;
    },
    [actionlist.MEDICATIONREPORTLIST_ADMINISTRATORSTATUS]: (state, administratorStatus) => {
        state.page = 0;
        state.administratorStatus = administratorStatus;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
