var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row row-sm" }, [
    _c("div", { staticClass: "col form-inline mg-b-5" }, [
      _c("div", [
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-secondary wd-80",
            attrs: { type: "button" },
            on: { click: _vm.previousPeriod }
          },
          [
            _c("i", { staticClass: "fas fa-caret-left" }),
            _vm._v(" " + _vm._s(_vm.$t("actions.previous")))
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-secondary wd-80",
            attrs: { type: "button" },
            on: { click: _vm.nextPeriod }
          },
          [
            _vm._v(_vm._s(_vm.$t("actions.next")) + " "),
            _c("i", { staticClass: "fas fa-caret-right" })
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mg-l-15 form-inline" }, [
        _c("div", { staticClass: "tx-bold tx-gray-900" }, [
          _vm.calendarType == "week"
            ? _c("span", [
                _vm._v(_vm._s(_vm._f("period")(_vm.startDate, _vm.endDate)))
              ])
            : _c("span", [
                _vm._v(_vm._s(_vm._f("month")(_vm.period, _vm.year)))
              ])
        ]),
        _vm._v(" "),
        _vm.calendarType == "week"
          ? _c("div", { staticClass: "mg-l-5" }, [
              _vm._v(
                "\r\n                    " +
                  _vm._s(_vm.$t("periods.week")) +
                  " " +
                  _vm._s(_vm.period) +
                  "\r\n                "
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ml-auto form-inline" })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }