var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.user.parentid != "0"
      ? _c(
          "div",
          { staticClass: "br-logo" },
          [
            _c("router-link", { attrs: { to: "/", exact: true } }, [
              _vm._v("BONT-rooster")
            ]),
            _vm._v(" "),
            _vm._m(0)
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "br-logo" },
          [
            _c(
              "router-link",
              { attrs: { to: { name: "schedule" }, exact: true } },
              [_vm._v("BONT-rooster")]
            ),
            _vm._v(" "),
            _vm._m(1)
          ],
          1
        ),
    _vm._v(" "),
    _c("div", { staticClass: "br-sideleft overflow-y-auto" }, [
      _c("label", { staticClass: "sidebar-label pd-x-15 mg-t-20" }, [
        _vm._v(_vm._s(_vm.$t("title.navigation")))
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "br-sideleft-menu" },
        [
          _vm.isAdministrator || _vm.isCoordinatingAttendant
            ? _c(
                "router-link",
                {
                  staticClass: "br-menu-link ",
                  attrs: {
                    to: { name: "schedule" },
                    exact: true,
                    "active-class": "active show-sub"
                  }
                },
                [
                  _c("div", { staticClass: "br-menu-item" }, [
                    _c("i", {
                      staticClass: "menu-item-icon icon ion-ios-home tx-22"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "menu-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("title.home")))
                    ])
                  ])
                ]
              )
            : _vm.AccessAttendant() || _vm.AccessClient(false)
            ? _c(
                "router-link",
                {
                  staticClass: "br-menu-link ",
                  attrs: {
                    to: "/",
                    exact: true,
                    "active-class": "active show-sub"
                  }
                },
                [
                  _c("div", { staticClass: "br-menu-item" }, [
                    _c("i", {
                      staticClass: "menu-item-icon icon ion-ios-home tx-22"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "menu-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("title.home")))
                    ])
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isAdministrator
            ? _c(
                "router-link",
                {
                  staticClass: "br-menu-link ",
                  attrs: {
                    to: { name: "schedule" },
                    exact: true,
                    "active-class": "active show-sub"
                  }
                },
                [
                  _c("div", { staticClass: "br-menu-item" }, [
                    _c("i", {
                      staticClass: "menu-item-icon icon ion-ios-calendar tx-22"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "menu-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("title.schedule")))
                    ])
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.AccessClient(false)
            ? _c(
                "router-link",
                {
                  staticClass: "br-menu-link ",
                  attrs: {
                    to: { name: "googleformurl" },
                    exact: true,
                    "active-class": "active show-sub"
                  }
                },
                [
                  _c("div", { staticClass: "br-menu-item" }, [
                    _c("i", {
                      staticClass: "menu-item-icon fa fa-clone tx-22"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "menu-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("title.googleformurl")))
                    ])
                  ])
                ]
              )
            : _vm.AccessAttendant()
            ? _c(
                "router-link",
                {
                  staticClass: "br-menu-link ",
                  attrs: {
                    to: { name: "publicprofile" },
                    exact: true,
                    "active-class": "active show-sub"
                  }
                },
                [
                  _c("div", { staticClass: "br-menu-item" }, [
                    _c("i", {
                      staticClass: "menu-item-icon fa fa-book  tx-22"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "menu-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("title.attendant")))
                    ])
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.AccessClient(false)
            ? _c(
                "router-link",
                {
                  staticClass: "br-menu-link ",
                  attrs: {
                    to: { name: "publicprofile" },
                    exact: true,
                    "active-class": "active show-sub"
                  }
                },
                [
                  _c("div", { staticClass: "br-menu-item" }, [
                    _c("i", {
                      staticClass: "menu-item-icon fa fa-book  tx-22"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "menu-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("title.publicprofile")))
                    ])
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.AccessClient(false)
            ? _c(
                "router-link",
                {
                  staticClass: "br-menu-link ",
                  attrs: {
                    to: { name: "clientmedication" },
                    exact: true,
                    "active-class": "active show-sub"
                  }
                },
                [
                  _c("div", { staticClass: "br-menu-item" }, [
                    _c("i", {
                      staticClass: "menu-item-icon fa fa-book  tx-22"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "menu-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("title.medication")))
                    ])
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isAdministrator || _vm.isCoordinatingAttendant
            ? [
                _c(
                  "a",
                  {
                    staticClass: "br-menu-link",
                    class: {
                      "active show-sub": _vm.subIsActive(_vm.$t("path.texts"))
                    },
                    attrs: { href: "javascript:;" }
                  },
                  [
                    _c("div", { staticClass: "br-menu-item" }, [
                      _c("i", {
                        staticClass: "menu-item-icon icon ion-ios-list tx-20"
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-item-label" }, [
                        _vm._v(_vm._s(_vm.$t("title.texts")))
                      ]),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "menu-item-arrow fa fa-angle-down"
                      })
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("ul", { staticClass: "br-menu-sub nav flex-column" }, [
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            to: { name: "faq" },
                            "active-class": "active"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("title.faq")))]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            to: { name: "manual" },
                            "active-class": "active"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("title.manual")))]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            to: { name: "welcome" },
                            "active-class": "active"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("title.welcome")))]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            to: { name: "contact" },
                            "active-class": "active"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("title.contact")))]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            to: { name: "texts_googleformurl" },
                            "active-class": "active"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("title.googleformurl")))]
                      )
                    ],
                    1
                  )
                ])
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.AccessClient(false) || _vm.AccessAttendant()
            ? _c(
                "router-link",
                {
                  staticClass: "br-menu-link ",
                  attrs: {
                    to: { name: "faqPage" },
                    exact: true,
                    "active-class": "active show-sub"
                  }
                },
                [
                  _c("div", { staticClass: "br-menu-item" }, [
                    _c("i", {
                      staticClass: "menu-item-icon fa fa-tasks tx-22"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "menu-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("title.faq")))
                    ])
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.AccessClient(false) || _vm.AccessAttendant()
            ? _c(
                "router-link",
                {
                  staticClass: "br-menu-link ",
                  attrs: {
                    to: { name: "contactPage" },
                    exact: true,
                    "active-class": "active show-sub"
                  }
                },
                [
                  _c("div", { staticClass: "br-menu-item" }, [
                    _c("i", {
                      staticClass: "menu-item-icon icon ion-ios-person tx-22"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "menu-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("title.contact")))
                    ])
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isAdministrator || _vm.isCoordinatingAttendant
            ? [
                _c(
                  "a",
                  {
                    staticClass: "br-menu-link",
                    class: {
                      "active show-sub": _vm.subIsActive(_vm.$t("path.manage"))
                    },
                    attrs: { href: "javascript:;" }
                  },
                  [
                    _c("div", { staticClass: "br-menu-item" }, [
                      _c("i", {
                        staticClass:
                          "menu-item-icon icon ion-ios-settings tx-20"
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-item-label" }, [
                        _vm._v(_vm._s(_vm.$t("title.manage")))
                      ]),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "menu-item-arrow fa fa-angle-down"
                      })
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("ul", { staticClass: "br-menu-sub nav flex-column" }, [
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            to: { name: "group" },
                            "active-class": "active"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("title.group")))]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            to: { name: "client" },
                            "active-class": "active"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("title.client")))]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            to: { name: "attendant" },
                            "active-class": "active"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("title.attendant")))]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            to: { name: "administrator" },
                            "active-class": "active"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("title.administrator")))]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            to: { name: "skill" },
                            "active-class": "active"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("title.skill")))]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            to: { name: "location" },
                            "active-class": "active"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("title.location")))]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            to: { name: "activitylabel" },
                            "active-class": "active"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("title.activitylabel")))]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            to: { name: "preparation" },
                            "active-class": "active"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("title.preparation")))]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            to: { name: "finishing" },
                            "active-class": "active"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("title.finishing")))]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            to: { name: "club" },
                            "active-class": "active"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("title.club")))]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            to: { name: "reportdefault" },
                            "active-class": "active"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("title.reports")))]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            to: { name: "managetimeofdaydetail" },
                            "active-class": "active"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("title.timeofdaydetail")))]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            to: { name: "activityconfigurations" },
                            "active-class": "active"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("title.configurations")))]
                      )
                    ],
                    1
                  )
                ])
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.isAdministrator || _vm.isCoordinatingAttendant
            ? [
                _c(
                  "a",
                  {
                    staticClass: "br-menu-link",
                    class: {
                      "active show-sub": _vm.subIsActive(
                        _vm.$t("path.activities")
                      )
                    },
                    attrs: { href: "javascript:;" }
                  },
                  [
                    _c("div", { staticClass: "br-menu-item" }, [
                      _c("i", {
                        staticClass:
                          "menu-item-icon icon ion-ios-settings tx-20"
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-item-label" }, [
                        _vm._v(_vm._s(_vm.$tc("title.activity", 2)))
                      ]),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "menu-item-arrow fa fa-angle-down"
                      })
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("ul", { staticClass: "br-menu-sub nav flex-column" }, [
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            to: { name: "activitytemplate" },
                            "active-class": "active"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("title.activitytemplate")))]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            to: { name: "activitiesbatch" },
                            "active-class": "active"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("title.activitiesbatch")))]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            to: { name: "activitylist" },
                            "active-class": "active"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("title.activitywarnings")))]
                      )
                    ],
                    1
                  )
                ])
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.isAdministrator
            ? _c(
                "router-link",
                {
                  staticClass: "br-menu-link ",
                  attrs: {
                    to: { name: "time" },
                    exact: true,
                    "active-class": "active show-sub"
                  }
                },
                [
                  _c("div", { staticClass: "br-menu-item" }, [
                    _c("i", {
                      staticClass: "menu-item-icon icon ion-ios-person tx-22"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "menu-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("title.timeregistration")))
                    ])
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.AccessAttendant()
            ? _c(
                "router-link",
                {
                  staticClass: "br-menu-link ",
                  attrs: {
                    to: { name: "manualattendant" },
                    exact: true,
                    "active-class": "active show-sub"
                  }
                },
                [
                  _c("div", { staticClass: "br-menu-item" }, [
                    _c("i", {
                      staticClass: "menu-item-icon icon ion-ios-person tx-22"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "menu-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("title.manual")))
                    ])
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.AccessAttendant()
            ? _c(
                "router-link",
                {
                  staticClass: "br-menu-link ",
                  attrs: {
                    to: { name: "timeregistration" },
                    exact: true,
                    "active-class": "active show-sub"
                  }
                },
                [
                  _c("div", { staticClass: "br-menu-item" }, [
                    _c("i", {
                      staticClass: "menu-item-icon icon ion-ios-person tx-22"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "menu-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("title.timeregistration")))
                    ])
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isAdministrator || _vm.isCoordinatingAttendant
            ? _c(
                "router-link",
                {
                  staticClass: "br-menu-link ",
                  attrs: {
                    to: { name: "admininvoice" },
                    exact: true,
                    "active-class": "active show-sub"
                  }
                },
                [
                  _c("div", { staticClass: "br-menu-item" }, [
                    _c("i", {
                      staticClass: "menu-item-icon icon ion-ios-person tx-22"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "menu-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("title.invoice")))
                    ])
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.AccessClient(false)
            ? _c(
                "router-link",
                {
                  staticClass: "br-menu-link ",
                  attrs: {
                    to: { name: "invoice" },
                    exact: true,
                    "active-class": "active show-sub"
                  }
                },
                [
                  _c("div", { staticClass: "br-menu-item" }, [
                    _c("i", {
                      staticClass: "menu-item-icon icon ion-ios-person tx-22"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "menu-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("title.invoice")))
                    ])
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isIndividualReportVisible &&
          (_vm.AccessClient(false) || _vm.isCoordinatingAttendant)
            ? _c(
                "router-link",
                {
                  staticClass: "br-menu-link ",
                  attrs: {
                    to: { name: "clientindividualreports" },
                    exact: true,
                    "active-class": "active show-sub"
                  }
                },
                [
                  _c("div", { staticClass: "br-menu-item" }, [
                    _c("i", {
                      staticClass: "menu-item-icon icon ion-ios-person tx-22"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "menu-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("title.individualreports")))
                    ])
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isAdministrator || _vm.isAttendant || _vm.isCoordinatingAttendant
            ? [
                _c(
                  "a",
                  {
                    staticClass: "br-menu-link",
                    class: {
                      "active show-sub": _vm.subIsActive(_vm.$t("path.reports"))
                    },
                    attrs: { href: "javascript:;" }
                  },
                  [
                    _c("div", { staticClass: "br-menu-item" }, [
                      _c("i", {
                        staticClass:
                          "menu-item-icon icon ion-ios-settings tx-20"
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-item-label" }, [
                        _vm._v(_vm._s(_vm.$t("title.reports")))
                      ]),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "menu-item-arrow fa fa-angle-down"
                      })
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("ul", { staticClass: "br-menu-sub nav flex-column" }, [
                  _vm.isAdministrator || _vm.isCoordinatingAttendant
                    ? _c(
                        "li",
                        { staticClass: "nav-item" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "nav-link",
                              attrs: {
                                to: { name: "reportpresence" },
                                "active-class": "active"
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("title.reportpresence")))]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAdministrator
                    ? _c(
                        "li",
                        { staticClass: "nav-item" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "nav-link",
                              attrs: {
                                to: { name: "reporttime" },
                                "active-class": "active"
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("title.timeregistration")))]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAdministrator
                    ? _c(
                        "li",
                        { staticClass: "nav-item" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "nav-link",
                              attrs: {
                                to: { name: "reportturnover" },
                                "active-class": "active"
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("title.turnover")))]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isCoordinatingAttendant
                    ? _c(
                        "li",
                        { staticClass: "nav-item" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "nav-link",
                              attrs: {
                                to: { name: "reportattendantcontract" },
                                "active-class": "active"
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("title.contracthoursperweek"))
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isCoordinatingAttendant
                    ? _c(
                        "li",
                        { staticClass: "nav-item" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "nav-link",
                              attrs: {
                                to: { name: "reportattendantleave" },
                                "active-class": "active"
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("title.leavehoursperyear")))]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAdministrator
                    ? _c(
                        "li",
                        { staticClass: "nav-item" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "nav-link",
                              attrs: {
                                to: { name: "reportnotinvoice" },
                                "active-class": "active"
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("title.notinvoice")))]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAdministrator
                    ? _c(
                        "li",
                        { staticClass: "nav-item" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "nav-link",
                              attrs: {
                                to: { name: "reportbudget" },
                                "active-class": "active"
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("title.budgetreports")))]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.isAdministrator || _vm.isCoordinatingAttendant
            ? [
                _c(
                  "a",
                  {
                    staticClass: "br-menu-link",
                    class: {
                      "active show-sub": _vm.subIsActive(
                        _vm.$t("path.heathcare")
                      )
                    },
                    attrs: { href: "javascript:;" }
                  },
                  [
                    _c("div", { staticClass: "br-menu-item" }, [
                      _c("i", {
                        staticClass:
                          "menu-item-icon icon ion-ios-settings tx-20"
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "menu-item-label" }, [
                        _vm._v(_vm._s(_vm.$t("title.healthcare")))
                      ]),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "menu-item-arrow fa fa-angle-down"
                      })
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("ul", { staticClass: "br-menu-sub nav flex-column" }, [
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            to: { name: "clubreports" },
                            "active-class": "active"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("title.clubreports")))]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            to: { name: "medicationrequest" },
                            "active-class": "active"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("title.medicationrequest")))]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            to: { name: "individualreports" },
                            "active-class": "active"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("title.individualreports")))]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: {
                            to: { name: "medicationreports" },
                            "active-class": "active"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("title.medicationreports")))]
                      )
                    ],
                    1
                  )
                ])
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.AccessAttendant()
            ? _c(
                "router-link",
                {
                  staticClass: "br-menu-link ",
                  attrs: {
                    to: { name: "birthday" },
                    exact: true,
                    "active-class": "active show-sub"
                  }
                },
                [
                  _c("div", { staticClass: "br-menu-item" }, [
                    _c("i", {
                      staticClass: "menu-item-icon icon ion-ios-person tx-22"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "menu-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("title.birthday")))
                    ])
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.AccessAttendant()
            ? _c(
                "router-link",
                {
                  staticClass: "br-menu-link ",
                  attrs: {
                    to: { name: "attendantcalender" },
                    exact: true,
                    "active-class": "active show-sub"
                  }
                },
                [
                  _c("div", { staticClass: "br-menu-item" }, [
                    _c("i", {
                      staticClass: "menu-item-icon icon ion-ios-person tx-22"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "menu-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("title.attendantcalender")))
                    ])
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "br-menu-link",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.logout.apply(null, arguments)
                }
              }
            },
            [
              _c("div", { staticClass: "br-menu-item" }, [
                _c("i", {
                  staticClass: "menu-item-icon icon ion-ios-exit tx-22"
                }),
                _vm._v(" "),
                _c("span", { staticClass: "menu-item-label" }, [
                  _vm._v(_vm._s(_vm.$t("actions.logout")))
                ])
              ])
            ]
          )
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-lg-none" }, [
      _c("a", { attrs: { id: "btnMenuClose", href: "javascript:;" } }, [
        _c("i", { staticClass: "ion ion-ios-close tx-30 mg-t-5 mg-l-30" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-lg-none" }, [
      _c("a", { attrs: { id: "btnMenuClose", href: "javascript:;" } }, [
        _c("i", { staticClass: "ion ion-ios-close tx-30 mg-t-5 mg-l-30" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }