import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import i18n from './i18n.js';


Vue.use(VueRouter);

const lazyRoutes = {
    login: () => import(/* webpackChunkName: "login" */ '../components/login/login.vue'),
    setPassword: () => import(/* webpackChunkName: "login" */ '../components/login/setpassword.vue'),
    editProfile: () => import(/* webpackChunkName: "login" */ '../components/login/editprofile.vue'),
    editReadOnlyProfile: () => import(/* webpackChunkName: "login" */ '../components/login/editprofilereadonly.vue'),
    forgotPassword: () => import(/* webpackChunkName: "login" */ '../components/login/forgotpassword.vue'),

    home: () => import(/* webpackChunkName: "home" */ '../components/home/home.vue'),

    textsFaq: () => import(/* webpackChunkName: "texts" */ '../components/texts/faq/faq.vue'),
    textsFaqList: () => import(/* webpackChunkName: "texts" */ '../components/texts/faq/list.vue'),
    textsFaqEdit: () => import(/* webpackChunkName: "texts" */ '../components/texts/faq/edit.vue'),
    textsManual: () => import(/* webpackChunkName: "texts" */ '../components/texts/manual/manual.vue'),
    textsManualList: () => import(/* webpackChunkName: "texts" */ '../components/texts/manual/list.vue'),
    textsManualEdit: () => import(/* webpackChunkName: "texts" */ '../components/texts/manual/edit.vue'),
    textsContact: () => import(/* webpackChunkName: "texts" */ '../components/texts/contact/contact.vue'),
    textsWelcome: () => import(/* webpackChunkName: "texts" */ '../components/texts/welcome/welcome.vue'),
    textsGoogleFormUrl: () => import(/* webpackChunkName: "texts" */ '../components/texts/googleform/url.vue'),

    manageGroup: () => import(/* webpackChunkName: "manage" */ '../components/manage/group/group.vue'),
    manageGroupList: () => import(/* webpackChunkName: "manage" */ '../components/manage/group/list.vue'),
    manageGroupEdit: () => import(/* webpackChunkName: "manage" */ '../components/manage/group/edit.vue'),
    manageClient: () => import(/* webpackChunkName: "manage" */ '../components/manage/client/client.vue'),
    manageClientList: () => import(/* webpackChunkName: "manage" */ '../components/manage/client/list.vue'),
    manageClientEdit: () => import(/* webpackChunkName: "manage" */ '../components/manage/client/edit.vue'),
    manageAttendant: () => import(/* webpackChunkName: "manage" */ '../components/manage/attendant/attendant.vue'),
    manageAttendantList: () => import(/* webpackChunkName: "manage" */ '../components/manage/attendant/list.vue'),
    manageAttendantEdit: () => import(/* webpackChunkName: "manage" */ '../components/manage/attendant/edit.vue'),
    manageAdministrator: () => import(/* webpackChunkName: "manage" */ '../components/manage/administrator/administrator.vue'),
    manageAdministratorList: () => import(/* webpackChunkName: "manage" */ '../components/manage/administrator/list.vue'),
    manageAdministratorEdit: () => import(/* webpackChunkName: "manage" */ '../components/manage/administrator/edit.vue'),
    manageSkill: () => import(/* webpackChunkName: "manage" */ '../components/manage/skills/skill.vue'),
    manageSkillList: () => import(/* webpackChunkName: "manage" */ '../components/manage/skills/list.vue'),
    manageSkillEdit: () => import(/* webpackChunkName: "manage" */ '../components/manage/skills/edit.vue'),

    manageLocation: () => import(/* webpackChunkName: "manage" */ '../components/manage/location/location.vue'),
    manageLocationList: () => import(/* webpackChunkName: "manage" */ '../components/manage/location/list.vue'),
    manageLocationEdit: () => import(/* webpackChunkName: "manage" */ '../components/manage/location/edit.vue'),
    schedule: () => import(/* webpackChunkName: "manage" */ '../components/schedule/schedule.vue'),

    manageTime: () => import(/* webpackChunkName: "manage" */ '../components/admintimeregistration/timeregistration.vue'),
    manageTimeList: () => import(/* webpackChunkName: "manage" */ '../components/admintimeregistration/list.vue'),


    manageActivityTemplate: () => import(/* webpackChunkName: "activities" */ '../components/activities/activitytemplate/activitytemplate.vue'),
    manageActivityTemplatelist: () => import(/* webpackChunkName: "activities" */ '../components/activities/activitytemplate/list.vue'),
    activityBatchUpdate: () => import(/* webpackChunkName: "activities" */ '../components/activities/batch/batchupdate.vue'),
    activityList: () => import(/* webpackChunkName: "activities" */ '../components/activities/activityactions/list.vue'),

    managePublicProfile: () => import(/* webpackChunkName: "textpages" */ '../components/publicprofile/publicprofile.vue'),
    managePublicProfileList: () => import(/* webpackChunkName: "textpages" */ '../components/publicprofile/list.vue'),
    contact: () => import(/* webpackChunkName: "textpages" */ '../components/contact/contact.vue'),
    faq: () => import(/* webpackChunkName: "textpages" */ '../components/faq/faq.vue'),
    manualkAttendant: () => import(/* webpackChunkName: "textpages" */ '../components/manual/attendant.vue'),
    googleFormUrl: () => import(/* webpackChunkName: "textpages" */ '../components/googleform/url.vue'),

    testEmail: () => import(/* webpackChunkName: "test" */ '../components/test/testemail.vue'),

    timeRegistration: () => import(/* webpackChunkName: "report" */ '../components/timeregistration/timeregistration.vue'),
    timeRegistrationList: () => import(/* webpackChunkName: "report" */ '../components/timeregistration/list.vue'),
    reports: () => import(/* webpackChunkName: "report" */ '../components/reports/reports.vue'),
    reportPresence: () => import(/* webpackChunkName: "report" */ '../components/reports/presence/report.vue'),
    reportTime: () => import(/* webpackChunkName: "report" */ '../components/reports/timeregistration/report.vue'),
    reportAttendantContract: () => import(/* webpackChunkName: "report" */ '../components/reports/attendantcontract/report.vue'),
    reportAttendantLeave: () => import(/* webpackChunkName: "report" */'../components/reports/attendantleave/report.vue'),
    reportTurnover: () => import(/* webpackChunkName: "report" */'../components/reports/turnover/report.vue'),
    reportNotInvoice: () => import(/* webpackChunkName: "report" */'../components/reports/notinvoice/report.vue'),
    reportBudget: () => import(/* webpackChunkName: "report" */'../components/reports/budget/report.vue'),

    admininvoice: () => import(/* webpackChunkName: "invoice" */ '../components/admininvoice/invoice.vue'),
    admininvoiceList: () => import(/* webpackChunkName: "invoice" */ '../components/admininvoice/list.vue'),
    admininvoiceEdit: () => import(/* webpackChunkName: "invoice" */ '../components/admininvoice/edit.vue'),
    admininvoiceEmailLog: () => import(/* webpackChunkName: "invoice" */ '../components/admininvoice/emaillist.vue'),
    admininvoiceSpecification: () => import(/* webpackChunkName: "invoice" */ '../components/admininvoice/specification.vue'),

    invoice: () => import(/* webpackChunkName: "invoice" */ '../components/invoice/invoice.vue'),
    invoiceList: () => import(/* webpackChunkName: "invoice" */ '../components/invoice/list.vue'),
    invoiceEdit: () => import(/* webpackChunkName: "invoice" */ '../components/invoice/edit.vue'),
    invoiceSpecification: () => import(/* webpackChunkName: "invoice" */ '../components/invoice/specification.vue'),

    activityLabel: () => import(/* webpackChunkName: "manage" */ '../components/manage/activitylabel/activitylabel.vue'),
    activityLabelList: () => import(/* webpackChunkName: "manage" */ '../components/manage/activitylabel/list.vue'),
    activityLabelEdit: () => import(/* webpackChunkName: "manage" */ '../components/manage/activitylabel/edit.vue'),

    preparation: () => import(/* webpackChunkName: "manage" */ '../components/manage/preparation/preparation.vue'),
    preparationList: () => import(/* webpackChunkName: "manage" */ '../components/manage/preparation/list.vue'),
    preparationEdit: () => import(/* webpackChunkName: "manage" */ '../components/manage/preparation/edit.vue'),

    finishing: () => import(/* webpackChunkName: "manage" */ '../components/manage/finishing/finishing.vue'),
    finishingList: () => import(/* webpackChunkName: "manage" */ '../components/manage/finishing/list.vue'),
    finishingEdit: () => import(/* webpackChunkName: "manage" */ '../components/manage/finishing/edit.vue'),

    club: () => import(/* webpackChunkName: "manage" */ '../components/manage/club/club.vue'),
    clubList: () => import(/* webpackChunkName: "manage" */ '../components/manage/club/list.vue'),
    clubEdit: () => import(/* webpackChunkName: "manage" */ '../components/manage/club/edit.vue'),

    reportdefault: () => import(/* webpackChunkName: "manage" */ '../components/manage/reportdefault/report.vue'),    
    reportdefaultEdit: () => import(/* webpackChunkName: "manage" */ '../components/manage/reportdefault/edit.vue'),


    healthcare: () => import(/* webpackChunkName: "manage" */ '../components/healthcare/healthcare.vue'),
    clubreports: () => import(/* webpackChunkName: "manage" */ '../components/healthcare/clubreports/report.vue'),
    medicationrequests: () => import(/* webpackChunkName: "manage" */ '../components/healthcare/medicationrequests/medicationrequests.vue'),
    medicationrequestsList: () => import(/* webpackChunkName: "manage" */ '../components/healthcare/medicationrequests/list.vue'),
    medicationrequestsEdit: () => import(/* webpackChunkName: "manage" */ '../components/healthcare/medicationrequests/edit.vue'),
    individualreports: () => import(/* webpackChunkName: "manage" */ '../components/healthcare/individualreports/individualreports.vue'),
    individualreportsList: () => import(/* webpackChunkName: "manage" */ '../components/healthcare/individualreports/list.vue'),
    individualreportsEdit: () => import(/* webpackChunkName: "manage" */ '../components/healthcare/individualreports/edit.vue'),
    medicatioreports: () => import(/* webpackChunkName: "manage" */ '../components/healthcare/medication/report.vue'),

    clientMedication: () => import(/* webpackChunkName: "manage" */ '../components/clientmedications/medication.vue'),
    clientMedicationList: () => import(/* webpackChunkName: "manage" */ '../components/clientmedications/list.vue'),
    clientMedicationEdit: () => import(/* webpackChunkName: "manage" */ '../components/clientmedications/edit.vue'),

    manageTimeOfDay: () => import(/* webpackChunkName: "manage" */ '../components/manage/timeofdaydetail/timeofdaydetail.vue'),

    clientindividualreports: () => import(/* webpackChunkName: "manage" */ '../components/clientindividualreports/clientindividualreports.vue'),
    clientindividualreportsList: () => import(/* webpackChunkName: "manage" */ '../components/clientindividualreports/list.vue'),
    clientindividualreportsEdit: () => import(/* webpackChunkName: "manage" */ '../components/clientindividualreports/edit.vue'),

    birthday: () => import(/* webpackChunkName: "manage" */ '../components/birthday/index.vue'),
    attendantcalender: () => import(/* webpackChunkName: "manage" */ '../components/attendantcalender/index.vue'),
    activityconfigurations: () => import(/* webpackChunkName: "manage" */ '../components/manage/configurations/index.vue'),
};


const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters['auth/isAuthenticated']) {
        next();
        return;
    }
    next('/');
};

const ifAuthenticated = (to, from, next) => {
    if (store.getters['auth/isAuthenticated']) {
        next();
        return;
    }
    next('/login');
};

const routes = [
    {
        name: 'login',
        path: i18n.t('path.login'),
        component: lazyRoutes.login,
        beforeEnter: ifNotAuthenticated,
        meta: { title: 'title.portal' }
    },
    {
        name: 'forgotpassword',
        path: i18n.t('path.forgotpassword'),
        component: lazyRoutes.forgotPassword,
        meta: { title: 'title.portal' }
    },
    {
        name: 'editprofile',
        path: i18n.t('path.editprofile'),
        component: lazyRoutes.editProfile,
        beforeEnter: ifAuthenticated,
        meta: { title: 'title.changepassword' }
    },
    {
        name: 'readonlyprofile',
        path: i18n.t('path.readonlyprofile'),
        component: lazyRoutes.editReadOnlyProfile,
        beforeEnter: ifAuthenticated,
        meta: { title: 'title.changepassword' }
    },
    {
        name: 'home',
        path: i18n.t('path.home'),
        component: lazyRoutes.home,
        beforeEnter: ifAuthenticated,
        meta: { title: 'title.portal' }
    },
    {
        name: 'googleformurl',
        path: i18n.t('path.googleformurl'),
        component: lazyRoutes.googleFormUrl,
        beforeEnter: ifAuthenticated,
        meta: { title: 'title.googleformurl' }
    },
    {
        name: 'texts_googleformurl',
        path: i18n.t('path.texts_googleformurl'),
        component: lazyRoutes.textsGoogleFormUrl,
        beforeEnter: ifAuthenticated,
        meta: { title: 'title.googleformurl' }
    },

    {
        path: i18n.t('path.texts_faq'),
        component: lazyRoutes.textsFaq,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'faq',
                path: '',
                component: lazyRoutes.textsFaqList,
                meta: { title: 'title.faq' }
            },
            {
                name: 'faqEdit',
                path: ':id',
                component: lazyRoutes.textsFaqEdit,
                meta: { title: 'title.faq' }
            },
        ]
    },
    {
        path: i18n.t('path.texts_manual'),
        component: lazyRoutes.textsManual,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'manual',
                path: '',
                component: lazyRoutes.textsManualList,
                meta: { title: 'title.manual' }
            },
            {
                name: 'manualEdit',
                path: ':id',
                component: lazyRoutes.textsManualEdit,
                meta: { title: 'title.manual' }
            }
        ]
    },
    {
        path: i18n.t('path.manage_group'),
        component: lazyRoutes.manageGroup,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'group',
                path: '',
                component: lazyRoutes.manageGroupList,
                meta: { title: 'title.group' }
            },
            {
                name: 'groupEdit',
                path: ':id',
                component: lazyRoutes.manageGroupEdit,
                meta: { title: 'title.group' }
            }
        ]
    },
    {
        path: i18n.t('path.manage_client'),
        component: lazyRoutes.manageClient,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'client',
                path: '',
                component: lazyRoutes.manageClientList,
                meta: { title: 'title.client' }
            },
            {
                name: 'clientEdit',
                path: ':id',
                component: lazyRoutes.manageClientEdit,
                meta: { title: 'title.client' }
            }
        ]
    },
    {
        path: i18n.t('path.manage_skill'),
        component: lazyRoutes.manageSkill,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'skill',
                path: '',
                component: lazyRoutes.manageSkillList,
                meta: { title: 'title.skill' }
            },
            {
                name: 'skillEdit',
                path: ':id',
                component: lazyRoutes.manageSkillEdit,
                meta: { title: 'title.skill' }
            }
        ]
    },
    {
        path: i18n.t('path.manage_time'),
        component: lazyRoutes.manageTime,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'time',
                path: '',
                component: lazyRoutes.manageTimeList,
                meta: { title: 'title.timeregistration' }
            },

        ]
    },
    {
        path: i18n.t('path.manage_attendant'),
        component: lazyRoutes.manageAttendant,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'attendant',
                path: '',
                component: lazyRoutes.manageAttendantList,
                meta: { title: 'title.attendant' }
            },
            {
                name: 'attendantEdit',
                path: ':id',
                component: lazyRoutes.manageAttendantEdit,
                meta: { title: 'title.attendant' }
            }
        ]
    },
    {
        path: i18n.t('path.manage_administrator'),
        component: lazyRoutes.manageAdministrator,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'administrator',
                path: '',
                component: lazyRoutes.manageAdministratorList,
                meta: { title: 'title.administrator' }
            },
            {
                name: 'administratorEdit',
                path: ':id',
                component: lazyRoutes.manageAdministratorEdit,
                meta: { title: 'title.administrator' }
            }
        ]
    },
    {
        path: i18n.t('path.activitytemplate'),
        component: lazyRoutes.manageActivityTemplate,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'activitytemplate',
                path: '',
                component: lazyRoutes.manageActivityTemplatelist,
                meta: { title: 'title.activitytemplate' }
            },
        ]
    },
    {
        path: i18n.t('path.timeregistration'),
        component: lazyRoutes.timeRegistration,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'timeregistration',
                path: '',
                component: lazyRoutes.timeRegistrationList,
                meta: { title: 'title.timeregistration' }
            },

        ]
    },
    {
        name: 'activitiesbatch',
        path: i18n.t('path.activitiesbatch'),
        component: lazyRoutes.activityBatchUpdate,
        beforeEnter: ifAuthenticated,
        meta: { title: 'title.activitiesbatch' }
    },
    {
        name: 'activitylist',
        path: i18n.t('path.activitylist'),
        component: lazyRoutes.activityList,
        beforeEnter: ifAuthenticated,
        meta: { title: 'title.activitylist' }
    },
    {
        name: 'contact',
        path: i18n.t('path.texts_contact'),
        component: lazyRoutes.textsContact,
        beforeEnter: ifAuthenticated,
        meta: { title: 'title.contact' }
    },
    {
        name: 'welcome',
        path: i18n.t('path.texts_welcome'),
        component: lazyRoutes.textsWelcome,
        beforeEnter: ifAuthenticated,
        meta: { title: 'title.welcome' }
    },
    {
        path: i18n.t('path.manage_publicprofile'),
        component: lazyRoutes.managePublicProfile,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'publicprofile',
                path: '',
                component: lazyRoutes.managePublicProfileList,
                meta: { title: 'title.publicprofile' }
            },
        ]
    },
    {
        name: 'contactPage',
        path: i18n.t('path.contact'),
        component: lazyRoutes.contact,
        beforeEnter: ifAuthenticated,
        meta: { title: 'title.contact' }
    },
    {
        name: 'faqPage',
        path: i18n.t('path.faq'),
        component: lazyRoutes.faq,
        beforeEnter: ifAuthenticated,
        meta: { title: 'title.faq' }
    },
    {
        name: 'manualattendant',
        path: i18n.t('path.manualattendant'),
        component: lazyRoutes.manualkAttendant,
        beforeEnter: ifAuthenticated,
        meta: { title: 'title.manual' }
    },
    {
        name: 'setpassword',
        path: i18n.t('path.setpassword'),
        component: lazyRoutes.setPassword,
        meta: { title: 'title.setpassword' }
    },
    {
        name: 'schedule',
        path: i18n.t('path.schedule'),
        component: lazyRoutes.schedule,
        meta: { title: 'title.schedule' }
    },
    {
        name: 'testemail',
        path: '/testemail',
        component: lazyRoutes.testEmail,
        meta: { title: 'Test e-mail' }
    },
    {
        path: i18n.t('path.reports'),
        component: lazyRoutes.reports,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'reportpresence',
                path: i18n.t('path.reportpresence'),
                component: lazyRoutes.reportPresence,
                meta: { title: 'title.reportpresence' },
            },
            {
                name: 'reporttime',
                path: i18n.t('path.reporttime'),
                component: lazyRoutes.reportTime,
                meta: { title: 'title.timeregistration' },
            },
            {
                name: 'reportattendantcontract',
                path: i18n.t('path.reportattendantcontract'),
                component: lazyRoutes.reportAttendantContract,
                meta: { title: 'title.contracthoursperweek' },
            },
            {
                name: 'reportattendantleave',
                path: i18n.t('path.reportattendantleave'),
                component: lazyRoutes.reportAttendantLeave,
                meta: { title: 'title.leavehoursperyear' },
            },
            {
                name: 'reportturnover',
                path: i18n.t('path.reportturnover'),
                component: lazyRoutes.reportTurnover,
                meta: { title: 'title.turnover' },
            },
            {
                name: 'reportnotinvoice',
                path: i18n.t('path.reportnotinvoice'),
                component: lazyRoutes.reportNotInvoice,
                meta: { title: 'title.notinvoice' },
            },
            {
                name: 'reportbudget',
                path: i18n.t('path.reportbudget'),
                component: lazyRoutes.reportBudget,
                meta: { title: 'title.budgetreports' },
            },
        ]
    },
    {
        path: i18n.t('path.admininvoice'),
        component: lazyRoutes.admininvoice,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'admininvoice',
                path: '',
                component: lazyRoutes.admininvoiceList,
                meta: { title: 'title.invoice' }
            },
            {
                name: 'admininvoiceEdit',
                path: ':id',
                component: lazyRoutes.admininvoiceEdit,
                meta: { title: 'title.invoice' },
                props: true
            },
            {
                name: 'admininvoiceEmailLog',
                path: 'emaillog/:invoiceid',
                component: lazyRoutes.admininvoiceEmailLog,
                meta: { title: 'title.invoice' },
                props: true
            },
            {
                name: 'admininvoiceSpecification',
                path: 'adminspecification/:id',
                component: lazyRoutes.admininvoiceSpecification,
                meta: { title: 'title.invoice' },
                props: true
            }

        ]
    },
    {
        path: i18n.t('path.invoice'),
        component: lazyRoutes.invoice,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'invoice',
                path: '',
                component: lazyRoutes.invoiceList,
                meta: { title: 'title.invoice' }
            },
            {
                name: 'invoiceEdit',
                path: ':id',
                component: lazyRoutes.invoiceEdit,
                meta: { title: 'title.invoice' },
                props: true
            },
            {
                name: 'invoiceSpecification',
                path: 'specification/:id',
                component: lazyRoutes.invoiceSpecification,
                meta: { title: 'title.invoice' },
                props: true
            }
        ]
    },
    {
        path: i18n.t('path.manage_location'),
        component: lazyRoutes.manageLocation,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'location',
                path: '',
                component: lazyRoutes.manageLocationList,
                meta: { title: 'title.location' }
            },
            {
                name: 'locationEdit',
                path: ':id',
                component: lazyRoutes.manageLocationEdit,
                meta: { title: 'title.location' },
                props: true
            }
        ]
    },
    {
        path: i18n.t('path.manage_activitylabel'),
        component: lazyRoutes.activityLabel,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'activitylabel',
                path: '',
                component: lazyRoutes.activityLabelList,
                meta: { title: 'title.activitylabel' }
            },
            {
                name: 'activitylabelEdit',
                path: ':id',
                component: lazyRoutes.activityLabelEdit,
                meta: { title: 'title.activitylabel' },
                props: true
            }
        ]
    },
    {
        path: i18n.t('path.manage_preparation'),
        component: lazyRoutes.preparation,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'preparation',
                path: '',
                component: lazyRoutes.preparationList,
                meta: { title: 'title.preparation' }
            },
            {
                name: 'preparationEdit',
                path: ':id',
                component: lazyRoutes.preparationEdit,
                meta: { title: 'title.preparation' },
                props: true
            }
        ]
    },
    {
        path: i18n.t('path.manage_finishing'),
        component: lazyRoutes.finishing,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'finishing',
                path: '',
                component: lazyRoutes.finishingList,
                meta: { title: 'title.finishing' }
            },
            {
                name: 'finishingEdit',
                path: ':id',
                component: lazyRoutes.finishingEdit,
                meta: { title: 'title.finishing' },
                props: true
            }
        ]
    },
    {
        path: i18n.t('path.manage_club'),
        component: lazyRoutes.club,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'club',
                path: '',
                component: lazyRoutes.clubList,
                meta: { title: 'title.club' }
            },
            {
                name: 'clubEdit',
                path: ':id',
                component: lazyRoutes.clubEdit,
                meta: { title: 'title.club' },
                props: true
            }
        ]
    },
    {
        path: i18n.t('path.clientmedication'),
        component: lazyRoutes.clientMedication,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'clientmedication',
                path: '',
                component: lazyRoutes.clientMedicationList,
                meta: { title: 'title.medication' }
            },
            {
                name: 'clientmedicationEdit',
                path: ':id',
                component: lazyRoutes.clientMedicationEdit,
                meta: { title: 'title.medication' },
                props: true
            }
        ]
    },
    {
        path: i18n.t('path.manage_reportdefault'),
        component: lazyRoutes.reportdefault,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'reportdefault',
                path: '',
                component: lazyRoutes.reportdefaultEdit,
                meta: { title: 'title.report' }
            },
            
        ]
    },
    {
        name: 'managetimeofdaydetail',
        path: i18n.t('path.manage_timeofdaydetail'),
        component: lazyRoutes.manageTimeOfDay,
        beforeEnter: ifAuthenticated,
        meta: { title: 'title.timeofdaydetail' }
    },
    {
        path: i18n.t('path.healthcare'),
        component: lazyRoutes.healthcare,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'clubreports',
                path: i18n.t('path.clubreports'),
                component: lazyRoutes.clubreports,
                meta: { title: 'title.clubreports' },
            }, 
            {
                name: 'medicationreports',
                path: i18n.t('path.medicationreports'),
                component: lazyRoutes.medicatioreports,
                meta: { title: 'title.medicationreports' },
            }, 
        ]
    },
    {
        path: i18n.t('path.medicationrequest'),
        component: lazyRoutes.medicationrequests,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'medicationrequest',
                path: '',
                component: lazyRoutes.medicationrequestsList,
                meta: { title: 'title.medicationrequest' }
            },
            {
                name: 'medicationrequestEdit',
                path: ':id',
                component: lazyRoutes.medicationrequestsEdit,
                meta: { title: 'title.medicationrequest' },
                props: true
            }
        ]
    },
    {
        path: i18n.t('path.individualreports'),
        component: lazyRoutes.individualreports,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'individualreports',
                path: '',
                component: lazyRoutes.individualreportsList,
                meta: { title: 'title.individualreports' }
            },
            {
                name: 'individualreportsEdit',
                path: ':id',
                component: lazyRoutes.individualreportsEdit,
                meta: { title: 'title.individualreports' },
                props: true
            }
        ]
    },
    {
        path: i18n.t('path.clientindividualreports'),
        component: lazyRoutes.clientindividualreports,
        beforeEnter: ifAuthenticated,
        children: [
            {
                name: 'clientindividualreports',
                path: '',
                component: lazyRoutes.clientindividualreportsList,
                meta: { title: 'title.individualreports' }
            },
            {
                name: 'clientindividualreportsEdit',
                path: ':id',
                component: lazyRoutes.clientindividualreportsEdit,
                meta: { title: 'title.individualreports' },
                props: true
            }
        ]
    },
    {
        name: 'birthday',
        path: i18n.t('path.birthday'),
        component: lazyRoutes.birthday,
        beforeEnter: ifAuthenticated,
        meta: { title: 'title.birthday' }
    },
    {
        name: 'attendantcalender',
        path: i18n.t('path.attendantcalender'),
        component: lazyRoutes.attendantcalender,
        beforeEnter: ifAuthenticated,
        meta: { title: 'title.attendantcalender' }
    },
    {
        name: 'activityconfigurations',
        path: i18n.t('path.configurations'),
        component: lazyRoutes.activityconfigurations,
        beforeEnter: ifAuthenticated,
        meta: { title: 'title.configurations' }
    },
];

const router = new VueRouter({ mode: 'history', routes });

router.afterEach((to, from) => {
    document.title = i18n.t(to.meta.title);
});

export default router;