import * as actionlist from '../actions/timelistactions';
import moment from 'moment';
const state = {
    search: '',
    page: 0,
    week: moment().isoWeek(),
    year: moment().year(),
    attendant: null,
    status: null,
    activityLabelId: null
};

const getters = {
    page: state => state.page,
    search: state => state.search,
    week: state => state.week,
    year: state => state.year,
    attendant: state => state.attendant,
    status: state => state.status,
    activityLabelId: state => state.activityLabelId
};

const actions = {
    [actionlist.TIMELIST_SEARCH]: ({ commit }, search) => {
        commit(actionlist.TIMELIST_SEARCH, search);
    },
    [actionlist.TIMELIST_PAGE]: ({ commit }, page) => {
        commit(actionlist.TIMELIST_PAGE, page);
    },
    [actionlist.TIMELIST_WEEK]: ({ commit }, week) => {
        commit(actionlist.TIMELIST_WEEK, week);
    },
    [actionlist.TIMELIST_YEAR]: ({ commit }, year) => {
        commit(actionlist.TIMELIST_YEAR, year);
    },
    [actionlist.TIMELIST_ATTENDANT]: ({ commit }, attendant) => {
        commit(actionlist.TIMELIST_ATTENDANT, attendant);
    },
    [actionlist.TIMELIST_STATUS]: ({ commit }, status) => {
        commit(actionlist.TIMELIST_STATUS, status);
    },
    [actionlist.TIMELIST_ACTIVITYLABELID]: ({ commit }, activityLabelId) => {
        commit(actionlist.TIMELIST_ACTIVITYLABELID, activityLabelId);
    },
};

const mutations = {
    [actionlist.TIMELIST_SEARCH]: (state, search) => {
        state.page = 0;
        state.search = search;
    },
    [actionlist.TIMELIST_PAGE]: (state, page) => {
        state.page = page;
    },
    [actionlist.TIMELIST_WEEK]: (state, week) => {
        state.page = 0;
        state.week = week;
    },
    [actionlist.TIMELIST_YEAR]: (state, year) => {
        state.page = 0;
        state.year = year;
    },
    [actionlist.TIMELIST_ATTENDANT]: (state, attendant) => {
        state.page = 0;
        state.attendant = attendant;
    },
    [actionlist.TIMELIST_STATUS]: (state, status) => {
        state.page = 0;
        state.status = status;
    },
    [actionlist.TIMELIST_ACTIVITYLABELID]: (state, activityLabelId) => {
        state.page = 0;
        state.activityLabelId = activityLabelId;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};