import viewtimepicker from 'vue2-timepicker';
export default {
    components: {
        'vue-timepicker': viewtimepicker
    },
    name: 'TimePicker',
    props: {
        minuteinterval: {
            type: Number,
            default: 15
        },
        value: {            
            type: Object
        },
        readonly: {
            type: Boolean,
            default: false
        },
        inputclass: {
            type: String,
            default: ''
        },
    },
    methods: {
        onChange(val) {
            let newValue = {
                HH:val.data.HH || '00',
                mm:val.data.mm || '00'
            };
            this.$emit('input', newValue);
        }
    }
};