var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isdecimal
    ? _c("input", {
        staticClass: "form-control",
        class: _vm.inputclass,
        attrs: {
          type: "number",
          name: _vm.name,
          step: "0.01",
          readonly: _vm.readonly
        },
        domProps: { value: _vm.value },
        on: {
          input: function($event) {
            _vm.$emit(
              "input",
              $event.target.value ? parseInt($event.target.value) : null
            )
          }
        }
      })
    : _c("input", {
        staticClass: "form-control",
        class: _vm.inputclass,
        attrs: { type: "number", name: _vm.name, readonly: _vm.readonly },
        domProps: { value: _vm.value },
        on: {
          input: function($event) {
            _vm.$emit(
              "input",
              $event.target.value ? parseInt($event.target.value) : null
            )
          }
        }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }