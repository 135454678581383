import { mapGetters } from 'vuex';
export default {
    name: 'ActivityBlock',
    props: {
        activity: {
            type: Object
        },
        dayOfWeek: {
            type: Number,
            default: 0
        },
        isMobile: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters('auth', [
            'isAdministrator',
        ]),
        cssClass() {
            return {
                'mg-l-1 bd-l': this.activity.isFirstDay,
                'mg-l--5': !this.activity.isFirstDay && this.dayOfWeek != 1 && !this.isMobile,
                'mg-r-1 bd-r': this.activity.isLastDay,
                'bd-success': this.activity.activityBorder == 'green' || (this.activity.activeColor == 'green' && this.activity.activityBorder == 'default'),
                'bd-warning': this.activity.activityBorder == 'orange' || (this.activity.activeColor == 'orange' && this.activity.activityBorder == 'default'),
                'bd-danger': this.activity.activityBorder == 'red',
                'bd-gray-600': !this.activity.activityBorder || (this.activity.activeColor == 'default' && this.activity.activityBorder == 'default'),
                'bg-success': this.activity.activeColor == 'green' || this.activity.color == 'green',
                'bg-warning': this.activity.activeColor == 'orange' || this.activity.color == 'orange',
                'red': this.activity.activeColor == 'red',
                'bg-white-9': !this.activity.activeColor || this.activity.activeColor == 'default' || this.activity.color == 'default',
                'lightred': this.activity.activeColor == 'lightred',
                'activityblockmobile':this.isMobile
            };
        }

    }
};