import * as actionlist from '../actions/invoicelistactions';
import moment from 'moment';
const state = {
    search: '',
    page: 0,
    month: moment().month() + 1,
    year: moment().year(),
    tomonth: moment().month() + 1,
    toyear: moment().year(),
    status: null
};

const getters = {
    page: state => state.page,
    search: state => state.search,
    month: state => state.month,
    year: state => state.year,
    tomonth: state => state.tomonth,
    toyear: state => state.toyear,
    status: state => state.status
};

const actions = {
    [actionlist.INVOICELIST_SEARCH]: ({ commit }, search) => {
        commit(actionlist.INVOICELIST_SEARCH, search);
    },
    [actionlist.INVOICELIST_PAGE]: ({ commit }, page) => {
        commit(actionlist.INVOICELIST_PAGE, page);
    },
    [actionlist.INVOICELIST_MONTH]: ({ commit }, month) => {
        commit(actionlist.INVOICELIST_MONTH, month);
    },
    [actionlist.INVOICELIST_YEAR]: ({ commit }, year) => {
        commit(actionlist.INVOICELIST_YEAR, year);
    },
    [actionlist.INVOICELIST_TOMONTH]: ({ commit }, tomonth) => {
        commit(actionlist.INVOICELIST_TOMONTH, tomonth);
    },
    [actionlist.INVOICELIST_TOYEAR]: ({ commit }, toyear) => {
        commit(actionlist.INVOICELIST_TOYEAR, toyear);
    },

    [actionlist.INVOICELIST_STATUS]: ({ commit }, status) => {
        commit(actionlist.INVOICELIST_STATUS, status);
    },
};

const mutations = {
    [actionlist.INVOICELIST_SEARCH]: (state, search) => {
        state.page = 0;
        state.search = search;
    },
    [actionlist.INVOICELIST_PAGE]: (state, page) => {
        state.page = page;
    },
    [actionlist.INVOICELIST_MONTH]: (state, month) => {
        state.page = 0;
        state.month = month;
    },
    [actionlist.INVOICELIST_YEAR]: (state, year) => {
        state.page = 0;
        state.year = year;
    },
    [actionlist.INVOICELIST_TOMONTH]: (state, tomonth) => {
        state.page = 0;
        state.tomonth = tomonth;
    },
    [actionlist.INVOICELIST_TOYEAR]: (state, toyear) => {
        state.page = 0;
        state.toyear = toyear;
    },

    [actionlist.INVOICELIST_STATUS]: (state, status) => {
        state.page = 0;
        state.status = status;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};