var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    directives: [
      {
        name: "model",
        rawName: "v-model",
        value: _vm.currentSearch,
        expression: "currentSearch"
      }
    ],
    staticClass: "form-control",
    attrs: {
      type: "search",
      id: "search",
      name: "search",
      placeholder: _vm.$t("actions.search")
    },
    domProps: { value: _vm.currentSearch },
    on: {
      input: function($event) {
        if ($event.target.composing) {
          return
        }
        _vm.currentSearch = $event.target.value
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }