var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vue-timepicker", {
    ref: "picker",
    class: _vm.inputclass,
    attrs: {
      format: "HH:mm",
      "minute-interval": _vm.minuteinterval,
      readonly: _vm.readonly,
      "time-value": _vm.value,
      value: _vm.value,
      hideClearButton: true
    },
    on: {
      "update:timeValue": function($event) {
        _vm.value = $event
      },
      "update:time-value": function($event) {
        _vm.value = $event
      },
      change: _vm.onChange
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }