var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col" }, [
    _c("div", { staticClass: "card h-100", class: _vm.getClass }, [
      _vm.showDayOfMonth
        ? _c("div", { staticClass: "card-title text-right pd-r-2 mg-b-0" }, [
            !_vm.isMobile
              ? _c("span", [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm._f("dayOfMonth")(_vm.day.date)) +
                      "\n            "
                  )
                ])
              : _c("span", [
                  _vm._v(
                    "                  \n                " +
                      _vm._s(_vm.weekdays[_vm.day.date.getDay() - 1]) +
                      "\n            "
                  )
                ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "card-body",
          class: { "use-add-cursor": _vm.isAdministrator },
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.$emit("newactivity", _vm.day.date)
            }
          }
        },
        _vm._l(_vm.day.activities, function(activity, index) {
          return _c("activity-block", {
            key: index,
            attrs: {
              isMobile: _vm.isMobile,
              activity: activity,
              dayOfWeek: _vm.day.dayOfWeek
            },
            on: {
              openactivity: function($event) {
                return _vm.$emit("openactivity", $event)
              },
              openrecuring: function($event) {
                return _vm.$emit("openrecuring", $event)
              }
            }
          })
        }),
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }