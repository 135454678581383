var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vue-datepicker-local", {
    attrs: {
      value: _vm.value,
      "input-class": _vm.inputclass,
      format: _vm.$t("format.date"),
      local: _vm.local,
      disabled: _vm.disabled,
      clearable: _vm.clearable
    },
    on: { input: _vm.inputChanged }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }