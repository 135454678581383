import * as actionlist from '../actions/attendantcontractactions';
import moment from 'moment';
const state = {
    search: '',
    page: 0,
    week: moment().week(),
    year: moment().year(),
    toweek: moment().week(),
    toyear: moment().year()
};

const getters = {
    page: state => state.page,
    search: state => state.search,
    week: state => state.week,
    toweek: state => state.toweek,
    year: state => state.year,
    toyear: state => state.toyear,
};

const actions = {
    [actionlist.ATTENDANRCONTRACTLIST_SEARCH]: ({ commit }, search) => {
        commit(actionlist.ATTENDANRCONTRACTLIST_SEARCH, search);
    },
    [actionlist.ATTENDANRCONTRACTLIST_PAGE]: ({ commit }, page) => {
        commit(actionlist.ATTENDANRCONTRACTLIST_PAGE, page);
    },
    [actionlist.ATTENDANRCONTRACTLIST_TOWEEK]: ({ commit }, toweek) => {
        commit(actionlist.ATTENDANRCONTRACTLIST_TOWEEK, toweek);
    },
    [actionlist.ATTENDANRCONTRACTLIST_TOYEAR]: ({ commit }, toyear) => {
        commit(actionlist.ATTENDANRCONTRACTLIST_TOYEAR, toyear);
    },
    [actionlist.ATTENDANRCONTRACTLIST_WEEK]: ({ commit }, week) => {
        commit(actionlist.ATTENDANRCONTRACTLIST_WEEK, week);
    },
    [actionlist.ATTENDANRCONTRACTLIST_YEAR]: ({ commit }, year) => {
        commit(actionlist.ATTENDANRCONTRACTLIST_YEAR, year);
    },
};

const mutations = {
    [actionlist.ATTENDANRCONTRACTLIST_SEARCH]: (state, search) => {
        state.page = 0;
        state.search = search;
    },
    [actionlist.ATTENDANRCONTRACTLIST_PAGE]: (state, page) => {
        state.page = page;
    },
    [actionlist.ATTENDANRCONTRACTLIST_WEEK]: (state, week) => {
        state.page = 0;
        state.week = week;
    },
    [actionlist.ATTENDANRCONTRACTLIST_YEAR]: (state, year) => {
        state.page = 0;
        state.year = year;
    },
    [actionlist.ATTENDANRCONTRACTLIST_TOWEEK]: (state, toweek) => {
        state.page = 0;
        state.toweek = toweek;
    },
    [actionlist.ATTENDANRCONTRACTLIST_TOYEAR]: (state, toyear) => {
        state.page = 0;
        state.toyear = toyear;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
