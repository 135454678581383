var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "activity-calendar",
      class: { "activity-calendar-week": "week" }
    },
    [
      _c("calendar-control-mobile", {
        on: {
          datechange: function($event) {
            _vm.date = $event
          },
          newactivity: function($event) {
            return _vm.$emit("newactivity", $event)
          }
        }
      }),
      _vm._v(" "),
      _vm.initialized
        ? _c(
            "div",
            _vm._l(_vm.activities, function(week) {
              return _c(
                "div",
                { key: week.key, staticClass: "row row-sm" },
                _vm._l(week, function(day) {
                  return _c("weekday", {
                    key: day.dayOfWeek,
                    attrs: { isMobile: true, day: day, active: true },
                    on: {
                      openactivity: function($event) {
                        return _vm.$emit("openactivity", $event)
                      },
                      newactivity: function($event) {
                        return _vm.$emit("newactivity", $event)
                      },
                      openrecuring: function($event) {
                        return _vm.$emit("openrecuring", $event)
                      }
                    }
                  })
                }),
                1
              )
            }),
            0
          )
        : _c("div", [_c("spinner")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }