import range from 'lodash.range';
import moment from 'moment';

export default {
    name: 'SelectYear',
    props: {
        value: Number,
        inputclass: {
            type: String,
            default: ''
        },
        emptyname: {
            type:String,
            default: null
        }
    },
    data() {
        return {
            years: range(moment().year()-1, moment().year()+1)
        };
    },
    computed: {
        options() {
            return this.years.map(x => {
                return {id:x, text: x};
            });
        },
    },
};