import * as actionlist from '../actions/turnoverreportactions';
import moment from 'moment';
const state = {
    search: '',
    page: 0,
    startdate: moment().subtract(1, 'months').date(1).toDate(),
    enddate: moment().subtract(1, 'months').endOf('month').toDate(),
    usertype: null,
    max: 2,
    
};

const getters = {
    page: state => state.page,
    search: state => state.search,
    startdate: state => state.startdate,
    enddate: state => state.enddate,
    
};

const actions = {
    [actionlist.TURNOVERREPORTLIST_SEARCH]: ({ commit }, search) => {
        commit(actionlist.TURNOVERREPORTLIST_SEARCH, search);
    },
    [actionlist.TURNOVERREPORTLIST_PAGE]: ({ commit }, page) => {
        commit(actionlist.TURNOVERREPORTLIST_PAGE, page);
    },
    [actionlist.TURNOVERREPORTLIST_STARTDATE]: ({ commit }, startdate) => {
        commit(actionlist.TURNOVERREPORTLIST_STARTDATE, startdate);
    },
    [actionlist.TURNOVERREPORTLIST_ENDDATE]: ({ commit }, enddate) => {
        commit(actionlist.TURNOVERREPORTLIST_ENDDATE, enddate);
    },
    
};

const mutations = {
    [actionlist.TURNOVERREPORTLIST_SEARCH]: (state, search) => {
        state.page = 0;
        state.search = search;
    },
    [actionlist.TURNOVERREPORTLIST_PAGE]: (state, page) => {
        state.page = page;
    },
    [actionlist.TURNOVERREPORTLIST_STARTDATE]: (state, startdate) => {
        state.page = 0;
        state.startdate = startdate;
    },
    [actionlist.TURNOVERREPORTLIST_ENDDATE]: (state, enddate) => {
        state.page = 0;
        state.enddate = enddate;
    },
    
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
