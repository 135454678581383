var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.progress
    ? _c(
        "div",
        {
          staticClass: "modal fade show bg-black-6",
          staticStyle: { display: "block" }
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-dialog modal-sm wd-500",
              attrs: { role: "document" }
            },
            [
              _c("div", { staticClass: "modal-content bd-0 tx-14" }, [
                _c(
                  "div",
                  { staticClass: "modal-body pd-20" },
                  [
                    _vm.progress > 0
                      ? _c(
                          "div",
                          { staticClass: "progress mg-b-20 mg-t-5 bd" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "progress-bar progress-bar-striped progress-bar-lg",
                                style:
                                  "width:" + Math.round(_vm.progress) + "%",
                                attrs: {
                                  role: "progressbar",
                                  "aria-valuenow": _vm.progress,
                                  "aria-valuemin": "0",
                                  "aria-valuemax": "100"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.progress) +
                                    "%\n                    "
                                )
                              ]
                            )
                          ]
                        )
                      : _c("spinner", { attrs: { heightClass: "" } }),
                    _vm._v(" "),
                    _vm.message
                      ? _c("div", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.message) +
                              "\n                "
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ])
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }