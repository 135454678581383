import axios from 'axios';
import weekdayHeader from './weekdayHeader.vue';
import birthdayweekday from './birthdayweekday.vue';
import moment from 'moment';
import debounce from 'lodash.debounce';
import range from 'lodash.range';
import 'moment-timezone';
import birthdaycalendarcontrol from './calendarbirthdayControl.vue';

export default {
    components: {
        weekdayHeader,
        birthdayweekday,
        'birthdaycalendar-control': birthdaycalendarcontrol
    },
    name: 'Calendar',
    data() {
        return {
            initialized: false,
            calendarType: 'month',
            year: moment().year(),
            period: moment().month() + 1,
            
            users: [],
            weekdays: [
                this.$t('periods.short.monday'),
                this.$t('periods.short.tuesday'),
                this.$t('periods.short.wednesday'),
                this.$t('periods.short.thursday'),
                this.$t('periods.short.friday'),
                this.$t('periods.short.saturday'),
                this.$t('periods.short.sunday')
            ]
        };
    },
    mounted() {
        this.fetch();
    },
    watch: {
        period() {
            this.onFilterUpdate();
        },
        year() {
            this.onFilterUpdate();
        },
        calendarType() {
            this.onFilterUpdate();
        },
       
    },
    computed: {
        weekdayHeaders() {
            if (this.calendarType == 'week') {
                let arr = [];
                for (let day in range(0, 7)) {
                    let dayOfMonth = moment(this.activities[0][day].date).format('D');
                    arr.push(this.weekdays[day] + ' ' + dayOfMonth);
                }
                return arr;
            }
            return this.weekdays;
        },
        
    },
    methods: {
        fetch() {
           
            this.initialized = false;
            return axios.get(APIURL + `/birthdaycalendar/${this.calendarType}/${this.year}/${this.period}`)
                .then(resp => {
                    this.users = resp.data;
                    this.initialized = true;
                });
        },
        debouncedFetch: debounce(function () { this.fetch(); }, 300),
        onFilterUpdate() {
            this.initialized = false;
            this.debouncedFetch();
        },
    },
};