import navmenu from '../navmenu/navmenu.vue';
import { AUTH_LOGOUT, AUTH_EXACHANGEUSER } from '../../store/actions/authactions';
import { mapActions, mapGetters } from 'vuex';


export default {
    components: {
        navmenu
    },
    name: 'App',
    computed: {
        ...mapGetters('auth', ['isAuthenticated', 'user', 'testenvironment', 'useradmin', 'isClientReadOnly'])
    },
    methods: {
        logout: function () {
            this.doLogout()
                .then(() => {
                    this.$router.push({ name: 'login' });
                });
        },
        adminlogin: function () {
            let self = this;
            let userdata = { username: this.user.username };
            this.exchangeuser(userdata)
                .then(() => {
                    if (self.user.role == 'Administrator')
                        this.$router.push({ name: 'schedule', query: { p: 1 } });
                    else
                        this.$router.push({ name: 'schedule' });

                });
        },
        ...mapActions('auth', {
            doLogout: AUTH_LOGOUT,
            exchangeuser: AUTH_EXACHANGEUSER
        }),
    }
};