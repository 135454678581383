export default {
    components: {
    },
    props: {
        
        value: {type:String}, name:{type:String},
        valueclass: {
            type:String,
            default:'col-8'
        }
    },
    name: 'ReadonlyText',
    data() {
        return {
        };
    },
};