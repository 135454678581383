import * as actionlist from '../actions/budgetreportactions';
import moment from 'moment';
import 'moment-timezone';
const state = {
    search: '',
    page: 0,
    startdate: moment().tz('UTC').date(1).toDate(),
    enddate: moment().tz('UTC').date(2).toDate(),
   
    
};

const getters = {
    page: state => state.page,
    search: state => state.search,
    startdate: state => state.startdate,
    enddate: state => state.enddate,
    
};

const actions = {
    [actionlist.BUDGETREPORTLIST_SEARCH]: ({ commit }, search) => {
        commit(actionlist.BUDGETREPORTLIST_SEARCH, search);
    },
    [actionlist.BUDGETREPORTLIST_PAGE]: ({ commit }, page) => {
        commit(actionlist.BUDGETREPORTLIST_PAGE, page);
    },
    [actionlist.BUDGETREPORTLIST_STARTDATE]: ({ commit }, startdate) => {
        commit(actionlist.BUDGETREPORTLIST_STARTDATE, startdate);
    },
    [actionlist.BUDGETREPORTLIST_ENDDATE]: ({ commit }, enddate) => {
        commit(actionlist.BUDGETREPORTLIST_ENDDATE, enddate);
    },
   
};

const mutations = {
    [actionlist.BUDGETREPORTLIST_SEARCH]: (state, search) => {
        state.page = 0;
        state.search = search;
    },
    [actionlist.BUDGETREPORTLIST_PAGE]: (state, page) => {
        state.page = page;
    },
    [actionlist.BUDGETREPORTLIST_STARTDATE]: (state, startdate) => {
        state.page = 0;
        state.startdate = startdate;
    },
    [actionlist.BUDGETREPORTLIST_ENDDATE]: (state, enddate) => {
        state.page = 0;
        state.enddate = enddate;
    },
    
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
