var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col" }, [
    _c("div", { staticClass: "card" }, [
      _c(
        "div",
        { staticClass: "card-title mg-b-0 text-center bg-dark tx-white" },
        [_vm._v("\n            " + _vm._s(_vm.title) + "\n        ")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }