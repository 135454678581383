import axios from 'axios';
import weekdayHeader from './weekdayHeader.vue';
import weekday from './weekday.vue';
import moment from 'moment';
import debounce from 'lodash.debounce';
import range from 'lodash.range';
import 'moment-timezone';

export default {
    components: {
        weekdayHeader,
        weekday
    },
    name: 'Calendar',
    data() {
        return {
            initialized: false,
            calendarType: 'month',
            year: moment().year(),
            period: moment().month() + 1,
            activityLabelId: 0,
            activities: [],
            weekdays: [
                this.$t('periods.short.monday'),
                this.$t('periods.short.tuesday'),
                this.$t('periods.short.wednesday'),
                this.$t('periods.short.thursday'),
                this.$t('periods.short.friday'),
                this.$t('periods.short.saturday'),
                this.$t('periods.short.sunday')
            ]
        };
    },
    mounted() {
        this.fetch();
    },
    watch: {
        period() {
            this.onFilterUpdate();
        },
        year() {
            this.onFilterUpdate();
        },
        calendarType() {
            this.onFilterUpdate();
        },
        activityLabelId() {
            this.onFilterUpdate();
        },
    },
    computed: {
        weekdayHeaders() {
            if (this.calendarType == 'week') {
                let arr = [];
                for (let day in range(0, 7)) {
                    let dayOfMonth = moment(this.activities[0][day].date).format('D');
                    arr.push(this.weekdays[day] + ' ' + dayOfMonth);
                }
                return arr;
            }
            return this.weekdays;
        },
        startDate() {
            if (this.activities.length == 0) return null;
            return this.activities[0][0].date;
        },
        endDate() {
            if (this.activities.length == 0) return null;
            let items = this.activities[this.activities.length - 1];
            return items[items.length - 1].date;
        }
    },
    methods: {
        fetch() {
            if (this.activityLabelId == null)
                this.activityLabelId = 0;
            this.initialized = false;
            return axios.get(APIURL + `/activity/${this.calendarType}/${this.year}/${this.period}/${this.activityLabelId}`)
                .then(resp => {
                    this.activities = resp.data;
                    this.initialized = true;
                });
        },
        debouncedFetch: debounce(function () { this.fetch(); }, 300),
        onFilterUpdate() {
            this.initialized = false;
            this.debouncedFetch();
        },
    },
};