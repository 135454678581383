var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.activity.isEmpty || _vm.isMobile
      ? _c(
          "div",
          {
            staticClass:
              "activityblock mg-t-1 mg-b-1 bd-y bd-2 pd-x-3 tx-gray-800 use-pointer",
            class: _vm.cssClass,
            staticStyle: { overflow: "hidden" },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.$emit("openactivity", {
                  id: _vm.activity.id,
                  state: _vm.activity.state,
                  activityPeriodId: _vm.activity.activityPeriodId
                })
              }
            }
          },
          [
            _vm.activity.isFirstDay || _vm.dayOfWeek == 1
              ? _c("div", [
                  _vm.isAdministrator && _vm.activity.isRecurring
                    ? _c("i", {
                        staticClass: "fa fa-redo-alt",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.$emit("openrecuring", _vm.activity.id)
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activity.color
                    ? _c("i", {
                        staticClass: "fa fa-square mg-l-2",
                        style: "color:" + _vm.activity.color
                      })
                    : _vm._e(),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.activity.title) +
                      "\n            "
                  ),
                  _c("div", { staticClass: "tx-9" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("time")(_vm.activity.startDateTime)) +
                        " - " +
                        _vm._s(_vm._f("time")(_vm.activity.endDateTime))
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.isMobile
              ? _c("div", { staticClass: "pd-20 mg-l-20" }, [
                  _vm.isAdministrator && _vm.activity.isRecurring
                    ? _c("i", {
                        staticClass: "fa fa-redo-alt",
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.$emit("openrecuring", _vm.activity.id)
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activity.color
                    ? _c("i", {
                        staticClass: "fa fa-square mg-l-2",
                        style: "color:" + _vm.activity.color
                      })
                    : _vm._e(),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.activity.title) +
                      "\n            "
                  ),
                  _c("div", { staticClass: "tx-9" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("time")(_vm.activity.startDateTime)) +
                        " - " +
                        _vm._s(_vm._f("time")(_vm.activity.endDateTime))
                    )
                  ])
                ])
              : _vm._e()
          ]
        )
      : _c("div", { staticClass: "activityblock mg-t-1 mg-b-1" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }