import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import grouplist from './modules/grouplist';
import clientlist from './modules/clientlist';
import attendantlist from './modules/attendantlist';
import administratorlist from './modules/administratorlist';
import publicprofilelist from './modules/publicprofilelist';
import activitytemplatelist from './modules/activitytemplatelist';
import manuallist from './modules/manuallist';
import activitylist from './modules/activitylist';
import skilllist from './modules/skilllist';
import timelist from './modules/timelist';
import presencelist from './modules/presencelist';
import timereportlist from './modules/timereportlist';
import attendantcontractreportlist from './modules/attendantcontractreportlist';
import attendantleavereportlist from './modules/attendantleavereportlist';
import invoicelist from './modules/invoicelist';
import locationlist from './modules/locationlist';
import activitylabellist from './modules/activitylabellist';
import turnoverreportlist from './modules/turnoverreportlist';
import notinvoicereportlist from './modules/notinvoicereportlist';
import preparationlist from './modules/preparationlist';
import finishinglist from './modules/finishinglist';
import clublist from './modules/clublist';
import clubreportlist from './modules/clubreportlist';
import budgetreportlist from './modules/budgetreportlist';
import clientindividualreportslist from './modules/clientindividualreportslist';
import individualreportslist from './modules/individualreportslist';
import medicationreportlist from './modules/medicationreportlist';
import schedulelist from './modules/schedulelist';
import clientmedicationlist from './modules/clientmedicationlist';
Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        grouplist,
        clientlist,
        attendantlist,
        administratorlist,
        publicprofilelist,
        activitytemplatelist,
        manuallist,
        activitylist,
        skilllist,
        timelist,
        presencelist,
        timereportlist,
        attendantcontractreportlist,
        attendantleavereportlist,
        invoicelist,
        locationlist,
        activitylabellist,
        turnoverreportlist,
        notinvoicereportlist,
        preparationlist,
        finishinglist,
        clublist,
        clubreportlist,
        budgetreportlist,
        clientindividualreportslist,
        individualreportslist,
        medicationreportlist,
        schedulelist,
        clientmedicationlist
    },
    strict: !PRODUCTION,
});