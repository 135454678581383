import * as actionlist from '../actions/activityactions';
import moment from 'moment';
const state = {
    search: '',
    page: 0,
    startDate: moment().toDate(),
    endDate: moment().add(1, 'M').toDate(),
};

const getters = {
    page: state => state.page,
    search: state => state.search,
    startDate: state => state.startDate,
    endDate: state => state.endDate,
};

const actions = {
    [actionlist.LIST_SEARCH]: ({ commit }, search) => {
        commit(actionlist.LIST_SEARCH, search);
    },
    [actionlist.LIST_PAGE]: ({ commit }, page) => {
        commit(actionlist.LIST_PAGE, page);
    },
    [actionlist.LIST_STARTDATE]: ({ commit }, startDate) => {
        commit(actionlist.LIST_STARTDATE, startDate);
    },
    [actionlist.LIST_ENDDATE]: ({ commit }, endDate) => {
        commit(actionlist.LIST_ENDDATE, endDate);
    },
};

const mutations = {
    [actionlist.LIST_SEARCH]: (state, search) => {
        state.page = 0;
        state.search = search;
    },
    [actionlist.LIST_PAGE]: (state, page) => {
        state.page = page;
    },
    [actionlist.LIST_STARTDATE]: (state, startDate) => {
        state.page = 0;
        state.startDate = startDate;
    },
    [actionlist.LIST_ENDDATE]: (state, endDate) => {
        state.page = 0;
        state.endDate = endDate;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};