import uniqid from 'uniqid';
import * as signalR from '@aspnet/signalr';

export default {
    name: 'ProgressDialog',
    data() {
        return {
            connection: null,
            progress: 0,
            guid: null,
            message: null
        };
    },
    methods: {
        start() {
            this.guid = uniqid();
            this.connection = new signalR.HubConnectionBuilder().withUrl(APIURL + '/progress').build();
            this.connection.on('updateProgressBar_' + this.guid, (perc) => {              
                this.progress = parseInt(perc, 10);
            });
            this.connection.on('updateProgressMessage_' + this.guid, (message) => {
                this.message = message;
            });
            return this.connection.start();
        },
        stop() {
            this.connection.stop();
            this.progress = null;
            this.guid = null;
        }
    }
};