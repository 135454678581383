var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "activity-calendar",
      class: {
        "activity-calendar-week": _vm.calendarType == "week",
        "activity-calendar-month": _vm.calendarType == "month"
      }
    },
    [
      _c("birthdaycalendar-control", {
        attrs: {
          calendarType: _vm.calendarType,
          year: _vm.year,
          period: _vm.period
        },
        on: {
          yearchange: function($event) {
            _vm.year = $event
          },
          periodchange: function($event) {
            _vm.period = $event
          }
        }
      }),
      _vm._v(" "),
      _vm.initialized
        ? _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "row row-sm" },
                [
                  _c("weekday-header", {
                    attrs: { title: _vm.weekdayHeaders[0] }
                  }),
                  _vm._v(" "),
                  _c("weekday-header", {
                    attrs: { title: _vm.weekdayHeaders[1] }
                  }),
                  _vm._v(" "),
                  _c("weekday-header", {
                    attrs: { title: _vm.weekdayHeaders[2] }
                  }),
                  _vm._v(" "),
                  _c("weekday-header", {
                    attrs: { title: _vm.weekdayHeaders[3] }
                  }),
                  _vm._v(" "),
                  _c("weekday-header", {
                    attrs: { title: _vm.weekdayHeaders[4] }
                  }),
                  _vm._v(" "),
                  _c("weekday-header", {
                    attrs: { title: _vm.weekdayHeaders[5] }
                  }),
                  _vm._v(" "),
                  _c("weekday-header", {
                    attrs: { title: _vm.weekdayHeaders[6] }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.users, function(week) {
                return _c(
                  "div",
                  {
                    key: week.key,
                    staticClass: "row row-sm",
                    class: { "mg-t-2": _vm.calendarType == "month" }
                  },
                  _vm._l(week, function(day) {
                    return _c("birthdayweekday", {
                      key: day.dayOfWeek,
                      attrs: {
                        day: day,
                        showDayOfMonth: _vm.calendarType == "month",
                        active:
                          (_vm.calendarType == "week" &&
                            day.week == _vm.period) ||
                          (_vm.calendarType == "month" &&
                            day.month == _vm.period)
                      }
                    })
                  }),
                  1
                )
              })
            ],
            2
          )
        : _c("div", [_c("spinner")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }