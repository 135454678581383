export default {
    props: {
        value: String,
        inputclass: {
            type: String,
            default: ''
        },
        readonly: {
            type: Boolean,
            default: false
        },
        rows: {
            type: Number,
            default: 3
        },
        validation: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: '-'
        },
    },
    name: 'InputTextarea'
};