var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("label", { staticClass: "col form-control-label" }, [
      _vm._v(_vm._s(_vm.name) + ":")
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mg-t-10 mg-sm-t-0", class: _vm.valueclass }, [
      _c("label", { staticClass: "col form-control-label tx-gray-800" }, [
        _vm._v(_vm._s(_vm.value))
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }