import axios from 'axios';
import weekdayHeader from './weekdayHeader.vue';
import weekday from './weekday.vue';
import moment from 'moment';
import debounce from 'lodash.debounce';
import range from 'lodash.range';
import 'moment-timezone';
import querystring from 'qs';
export default {
    components: {

        weekday,
        weekdayHeader
    },
    name: 'CalendarMobile',
    data() {
        return {
            initialized: false,
            date: moment().toDate(),
            activityLabelId: 0,
            activities: [],
            weekdays: [
                this.$t('periods.short.monday'),
                this.$t('periods.short.tuesday'),
                this.$t('periods.short.wednesday'),
                this.$t('periods.short.thursday'),
                this.$t('periods.short.friday'),
                this.$t('periods.short.saturday'),
                this.$t('periods.short.sunday')
            ]
        };
    },
    mounted() {
        this.fetch();
    },
    watch: {
        date() {
            this.onFilterUpdate();
        },

    },
    computed: {

    },
    methods: {
        fetch() {
            if (this.activityLabelId == null)
                this.activityLabelId = 0;
            const params = {
                date: this.date,
            };
            this.initialized = false;
            var url = APIURL + `/activity/date/${this.activityLabelId}/?` + querystring.stringify(params);
            return axios.get(url)
                .then(resp => {
                    this.activities = resp.data;
                    this.initialized = true;
                });
        },
        debouncedFetch: debounce(function () { this.fetch(); }, 300),
        onFilterUpdate() {
            this.initialized = false;
            this.debouncedFetch();
        },
    },
};