var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("textarea", {
    directives: [
      {
        name: "validate",
        rawName: "v-validate",
        value: _vm.validation,
        expression: "validation"
      }
    ],
    staticClass: "form-control form-control-sm",
    class: _vm.inputclass,
    attrs: { rows: _vm.rows, readonly: _vm.readonly, name: _vm.name },
    domProps: { value: _vm.value },
    on: {
      input: function($event) {
        return _vm.$emit("input", $event.target.value)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }