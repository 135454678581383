import * as actionlist from '../actions/presenceactions';
import moment from 'moment';
const state = {
    search: '',
    page: 0,
    monthstart: moment().month() + 1,
    monthend: moment().month() + 1,
    yearstart: moment().year(),
    yearend: moment().year(),
    usertype: null,
    max: 2
};

const getters = {
    page: state => state.page,
    search: state => state.search,
    monthstart: state => state.monthstart,
    monthend: state => state.monthend,
    yearstart: state => state.yearstart,
    yearend: state => state.yearend,
    usertype: state => state.usertype,
    max: state => state.max
};

const actions = {
    [actionlist.PRESENCELIST_SEARCH]: ({ commit }, search) => {
        commit(actionlist.PRESENCELIST_SEARCH, search);
    },
    [actionlist.PRESENCELIST_PAGE]: ({ commit }, page) => {
        commit(actionlist.PRESENCELIST_PAGE, page);
    },
    [actionlist.PRESENCELIST_MONTHSTART]: ({ commit }, monthstart) => {
        commit(actionlist.PRESENCELIST_MONTHSTART, monthstart);
    },
    [actionlist.PRESENCELIST_MONTHEND]: ({ commit }, monthend) => {
        commit(actionlist.PRESENCELIST_MONTHEND, monthend);
    },
    [actionlist.PRESENCELIST_YEARSTART]: ({ commit }, yearstart) => {
        commit(actionlist.PRESENCELIST_YEARSTART, yearstart);
    },
    [actionlist.PRESENCELIST_YEAREND]: ({ commit }, yearend) => {
        commit(actionlist.PRESENCELIST_YEAREND, yearend);
    },
    [actionlist.PRESENCELIST_USERTYPE]: ({ commit }, usertype) => {
        commit(actionlist.PRESENCELIST_USERTYPE, usertype);
    },
    [actionlist.PRESENCELIST_MAX]: ({ commit }, max) => {
        commit(actionlist.PRESENCELIST_MAX, max);
    },
};

const mutations = {
    [actionlist.PRESENCELIST_SEARCH]: (state, search) => {
        state.page = 0;
        state.search = search;
    },
    [actionlist.PRESENCELIST_PAGE]: (state, page) => {
        state.page = page;
    },
    [actionlist.PRESENCELIST_MONTHSTART]: (state, monthstart) => {
        state.page = 0;
        state.monthstart = monthstart;
    },
    [actionlist.PRESENCELIST_MONTHEND]: (state, monthend) => {
        state.page = 0;
        state.monthend = monthend;
    },
    [actionlist.PRESENCELIST_YEARSTART]: (state, yearstart) => {
        state.page = 0;
        state.yearstart = yearstart;
    },
    [actionlist.PRESENCELIST_YEAREND]: (state, yearend) => {
        state.page = 0;
        state.yearend = yearend;
    },
    [actionlist.PRESENCELIST_USERTYPE]: (state, usertype) => {
        state.page = 0;
        state.usertype = usertype;
    },
    [actionlist.PRESENCELIST_MAX]: (state, max) => {
        state.page = 0;
        state.max = max;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
