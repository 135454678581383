import listmodule from './list.js';

const state = {
    ...listmodule.state
};

const getters = {
    ...listmodule.getters
};

const actions = {
    ...listmodule.actions
};

const mutations = {
    ...listmodule.mutations
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};