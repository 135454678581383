import * as actionlist from '../actions/attendantleaveactions';
import moment from 'moment';
const state = {
    search: '',
    page: 0,
    year: moment().year(),

};

const getters = {
    page: state => state.page,
    search: state => state.search,  
    year: state => state.year,    
};

const actions = {
    [actionlist.ATTENDANTLEAVELIST_SEARCH]: ({ commit }, search) => {
        commit(actionlist.ATTENDANTLEAVELIST_SEARCH, search);
    },
    [actionlist.ATTENDANTLEAVELIST_PAGE]: ({ commit }, page) => {
        commit(actionlist.ATTENDANTLEAVELIST_PAGE, page);
    },
   
    [actionlist.ATTENDANTLEAVELIST_YEAR]: ({ commit }, year) => {
        commit(actionlist.ATTENDANTLEAVELIST_YEAR, year);
    },
};

const mutations = {
    [actionlist.ATTENDANTLEAVELIST_SEARCH]: (state, search) => {
        state.page = 0;
        state.search = search;
    },
    [actionlist.ATTENDANTLEAVELIST_PAGE]: (state, page) => {
        state.page = page;
    },
    [actionlist.ATTENDANTLEAVELIST_YEAR]: (state, year) => {
        state.page = 0;
        state.year = year;
    },    
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
