import listmodule from './list.js';
import { ATTENDANTLIST_STATUS } from '../actions/attendantlistactions'; 
const state = {
    ...listmodule.state,
    status:null
};

const getters = {
    ...listmodule.getters,
    status: state => state.status,
};

const actions = {
    ...listmodule.actions,
    [ATTENDANTLIST_STATUS]: ({ commit }, status) => {
        console.log('status' + status)
        commit(ATTENDANTLIST_STATUS, status);
    },
};

const mutations = {
    ...listmodule.mutations,
    [ATTENDANTLIST_STATUS]: (state, status) => {
        state.status = status;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};