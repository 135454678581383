var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col" }, [
    _c("div", { staticClass: "card h-100", class: _vm.getClass }, [
      _vm.showDayOfMonth
        ? _c("div", { staticClass: "card-title text-right pd-r-2 mg-b-0" }, [
            !_vm.isMobile
              ? _c("span", [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm._f("dayOfMonth")(_vm.day.date)) +
                      "\n            "
                  )
                ])
              : _c("span", [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.weekdays[_vm.day.date.getDay() - 1]) +
                      "\n            "
                  )
                ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-body" },
        _vm._l(_vm.day.users, function(user, index) {
          return _c("div", { key: index, staticClass: "mg-l-10 pd-2" }, [
            _c("i", {
              class: user.isClient ? "fa wd-15 fa-user" : "fa wd-20 fa-users"
            }),
            _vm._v(" " + _vm._s(user.name) + "\n            ")
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }