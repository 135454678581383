var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isAuthenticated ? _c("navmenu") : _vm._e(),
      _vm._v(" "),
      _vm.isAuthenticated
        ? _c("div", { staticClass: "br-header" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "br-header-left" }, [
              _vm.testenvironment === "True" && !_vm.useradmin.username
                ? _c("h2", [_vm._v(_vm._s(_vm.$t("title.testenvironment")))])
                : _vm._e(),
              _vm._v(" \n            "),
              _vm.useradmin.username
                ? _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-primary tx-11 tx-uppercase pd-y-12 pd-x-25 tx-mont tx-medium",
                      attrs: { type: "button" },
                      on: { click: _vm.adminlogin }
                    },
                    [_vm._v(_vm._s(_vm.$t("actions.backtoadmin")))]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "br-header-right" }, [
              _c("nav", { staticClass: "nav" }, [
                _c("div", { staticClass: "dropdown" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link nav-link-profile",
                      attrs: {
                        href: "",
                        "data-toggle": "dropdown",
                        "aria-expanded": "false"
                      }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "logged-name hidden-md-down" },
                        [_vm._v(_vm._s(_vm.user.username))]
                      ),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "wd-32 rounded-circle",
                        attrs: {
                          src: "https://via.placeholder.com/64x64",
                          alt: ""
                        }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "square-10 bg-success" })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "dropdown-menu dropdown-menu-header wd-200",
                      staticStyle: {
                        position: "absolute",
                        transform: "translate3d(-18px, 48px, 0px)",
                        top: "0px",
                        left: "0px",
                        "will-change": "transform"
                      },
                      attrs: { "x-placement": "bottom-start" }
                    },
                    [
                      _c(
                        "ul",
                        { staticClass: "list-unstyled user-profile-nav" },
                        [
                          !_vm.isClientReadOnly
                            ? _c(
                                "li",
                                [
                                  _c(
                                    "router-link",
                                    { attrs: { to: { name: "editprofile" } } },
                                    [
                                      _c("i", {
                                        staticClass: "icon ion-ios-person"
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("title.editprofile"))
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isClientReadOnly
                            ? _c(
                                "li",
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: { to: { name: "readonlyprofile" } }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "icon ion-ios-person"
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("title.editprofile"))
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("li", [
                            _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: { click: _vm.logout }
                              },
                              [
                                _c("i", { staticClass: "icon ion-ios-power" }),
                                _vm._v(" " + _vm._s(_vm.$t("actions.logout")))
                              ]
                            )
                          ])
                        ]
                      )
                    ]
                  )
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { class: { "br-mainpanel": _vm.isAuthenticated } },
        [_c("router-view", { key: _vm.$route.fullPath })],
        1
      ),
      _vm._v(" "),
      _c("vuedal")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "br-header-left" }, [
      _c("div", { staticClass: "navicon-left d-none d-lg-flex" }, [
        _c("a", { attrs: { id: "btnLeftMenu" } }, [
          _c("i", { staticClass: "ion ion-ios-menu" })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "navicon-left d-flex d-lg-none" }, [
        _c("a", { attrs: { id: "btnLeftMenuMobile", href: "javascript:;" } }, [
          _c("i", { staticClass: "ion ion-ios-menu" })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }