export default {
    name: 'FormGroup',
    props: {
        label: String,
        labelclass: {
            type: String,
            default: ''
        },
        groupclass: {
            type: String,
            default: ''
        },
        required: {
            type: Boolean,
            default: false
        },
        labelwidth: {
            type:Number,
            default:4
        }
    },
    computed: {
        labelcss() {
            return 'col-12 col-md-'+this.labelwidth+' '+this.labelclass;
        }
    },
    data() {
        return {
        };
    },
};