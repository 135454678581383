import axios from 'axios';
import { strict } from 'assert';

export default {
    name: 'SelectEnumType',
    props: {
        value: [Number, String],
        inputclass: {
            type: String,
            default: ''
        },
        emptyname: {
            type: String,
            default: null
        },
        includeInactive: {
            type: Boolean,
            default: false
        },
        enumtype: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        transalation: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            list: []
        };
    },
    computed: {
        options() {
            return this.list.map(x => {
                return { id: x.id, text: this.transalation ? this.$t('enum.' + this.enumtype + '.' + x.name) : x.name };
            });
        },
    },
    created() {
        this.fetch();
    },
    methods: {
        fetch() {
            axios.get(APIURL + '/activity/getenum/?type=' + this.enumtype)
                .then(resp => this.list = resp.data);
        },
    }
};